export default function AnimProjectLines() {

    return (
        <>
            <div class="anim-splash">
              <svg id="portfolio-svg" viewBox="0 0 264.1 558.8" width="264.1" height="558.8">
  <path class="drawn-lines" d="M227.8,397.7c0,1.5,0.1,3-0.4,4.5c-0.5,1.6,0.2,3.2-0.1,4.9c-0.6,2.9-1.3,5.9-1.6,8.8
    c-0.6,4.8-2.2,9.3-3.8,13.9c-0.9,2.5-1.3,5.2-2.2,7.8c-1.2,3.4-3.1,6.4-4.4,9.7c-2.3,5.9-5.6,11.3-8.8,16.8c-2,3.6-4,7.2-5.7,10.9
    c-0.9,1.9-1.9,3.8-3.1,5.6c-2.2,3.2-3.5,6.8-4,10.6c-0.2,1.9-0.7,3.8-0.9,5.8c-0.2,1.3,0.3,2.4,0.2,3.6c0,4.9,0.1,9.8,0.2,14.8
    c0.1,5.5,0.6,10.9,1.1,16.4c0.2,2.5-0.1,5,0.1,7.6c0.2,4.5-0.5,8.9-0.2,13.3c0.1,1.9,0.4,3.7,0.5,5.6"/>
  <path class="drawn-lines" d="M188.4,385.8c-1.3-1-1.6-2.6-2.3-4c-1.7-3.5-3.1-7.2-5.4-10.5c-1-1.5-2.1-3.1-3.5-4c-3.9-2.7-7.9-5.1-12.2-7.1
    c-2.7-1.3-5.8-2-7.8-4.4c-0.7-0.8-1.7-0.5-2.5-0.6c-3.3-0.7-6-2.6-8.6-4.5c-1.9-1.4-3.8-2.3-6.1-2.3c-0.9,0-1.7-0.3-2.6-0.8
    c-1.8-1-3.3-2.5-5.5-2.8c-0.8-0.1-1.6-1-2.3-1.6c-1.7-1.5-3.8-2.3-5.8-3.2"/>
  <path class="drawn-lines" d="M153.7,239.1c-1.3,0.3-2.6,0.6-3.8-0.2c-0.6-0.4-1.3-0.1-2-0.2c-0.5-0.1-1.1,0.2-1.6-0.2
    c-1.9-1.5-4.1-1-6.1-0.9c-1.5,0.1-2.9,0.1-4.1-0.8c-0.3-0.2-0.7-0.4-1.1-0.4c-3.1,0.5-6.1-1.6-9.4-0.5c-0.7,0.2-2.1,0.3-2.9-0.8
    c-0.4-0.5-1.4-0.4-1.9-0.2c-1.4,0.7-2.4,0-3.4-0.6c-1.1-0.7-2.2-0.9-3.4-0.9c-2,0-4-0.2-5.9-0.3c-3.3-0.2-6.5,0.9-9.7,1.8
    c-2.9,0.9-5.9,1.6-8.3,3.7c-4.1,0.6-6.8,3.8-10.2,5.6c-0.2,0.1-0.4,0.4-0.5,0.5"/>
  <path class="drawn-lines" d="M131,492.7c0.8-0.8,1.6-1.1,2.5-0.7c-0.8,0.7-1.4,1.9-2.7,0.7c-0.2-0.2-0.6,0.1-0.9,0.3
    c-3.1,2.4-6.3,4.5-10.2,5.3c-1.6,0.4-3,1.6-4.7,1.9c-4.2,0.6-8.2,2.4-12.6,2.4c-2.4,0-5,0.3-7.4,1c-0.6,0.2-1.1,0.4-1.3,0.9"/>
  <path class="drawn-lines" d="M135,259.5c0.7-1.4-0.3-1.7-1.3-1.8c-1.1-0.1-2.3,0-3.4,0c-1.3,0-2.5,0.1-3.8-0.3c-2.4-0.8-4.8,0.1-7.2,0.4
    c-3.8,0.5-7.5,1-11.3,1.3c-2.9,0.2-5.9,0.3-8.6,1.4c-2.2,0.9-4.4,2.3-6.6,3.4c-4.3,2.3-6.8,6.4-9.9,9.9c-0.4,0.4,0.1,1-0.4,1.1
    c0.4,0.8,1.3,0.3,1.7,0.8"/>
  <path class="drawn-lines" d="M116.6,32.3c-0.4,0.1-0.9,0.1-1.1,0.4c-2.5,3.1-6.1,3.6-9.6,4.5c-4.8,1.2-9.6,2.6-14.1,5
    c-2.7,1.4-5.1,3.1-7.5,5c-0.4,0.3-0.9,0.6-0.9,1.3"/>
  <path class="drawn-lines" d="M54.7,264.3c-0.1,1.7,0.6,3.3,1.1,4.9c0.8,2.8,0.9,5.8,2.2,8.5c1.6,3.5,3.2,6.9,4.7,10.4
    c1.2,2.9,3.5,5.3,4.4,8.4c0.2,0.9,0.7,2,2.1,2"/>
  <path class="drawn-lines" d="M127.4,411.5c0.2,1.2-0.2,1.7-1.6,2.1c-2.8,0.8-5.6,1.1-8.5,1.3c-2.3,0.2-4.7-0.4-7-0.6
    c-2.9-0.3-5.6-1.1-8.4-1.5c-3.1-0.5-6-0.4-9,0.2c-1.6,0.4-3.2,0.2-4.7-0.4c-0.8-0.3-1.5-0.5-2.3-0.3"/>
  <path class="drawn-lines" d="M156.6,393.5c-2.8,5.5-7.2,9.5-12.2,13c-4.6,3.2-9.8,5.4-14.7,8c-1.1,0.6-2,1.8-3.4,1.7c-0.3,0-0.3,0.3-0.4,0.5
    c-0.6,2.2-2.4,3.3-4.3,4.1c-0.5,0.2-1.1,0.4-1.1,1.1"/>
  <path class="drawn-lines" d="M68,296.2c0.5,0,0.8,0.3,0.9,0.7c0.8,3,3.1,5,4.8,7.4c1.7,2.4,3.3,4.9,4.8,7.4c1.6,2.7,3.5,5.2,4.9,8.1
    c1.3,2.5,2.8,5,3.6,7.7c0.2,0.6,0.6,1.1,0.9,1.6"/>
  <path class="drawn-lines" d="M194.2,297.1c-0.7-2.3-1.5-2.9-4.3-3.1c-3.2-0.3-6.4-0.7-9.5-1c-1.1-0.1-2.2,0.3-3.2,0.4
    c-2.9,0.4-5.8,0.4-8.7,1.3c-2.2,0.7-4.1,2-6.4,2.5c-2.8,0.6-5.1,2.5-7.7,3.6c-1.2,0.6-2.4,1.2-3.8,1.4c-0.8,0.1-1.4,0.7-2,0.8
    c-3.1,0.5-6.3,1.5-9.5,1c-0.2,0-0.4-0.2-0.6-0.3c0.4-0.4,0.8-0.5,1.3-0.2"/>
  <path class="drawn-lines" d="M135.5,258.9c2.8-1,5,0.5,7.2,1.6c5.1,2.6,9.7,6,15,8.2c2.5,1,5,2.1,7.4,3.4c0.9,0.5,0.6,0.8,0.3,1.3"/>
  <path class="drawn-lines" d="M120.2,422.7c-2.1,1.4-4.2,2.9-6.3,4.3c-1.4,0.9-2.8,1.6-4.5,1.9c-2.4,0.4-4.5,1.8-6.7,2.8
    c-3.4,1.6-7.1,2.8-10.6,4.2"/>
  <path class="drawn-lines" d="M192,394.3c-0.5,0.5-0.4,1.2-0.3,1.8c0.6,3.3,1.4,6.6,1.9,9.9c0.5,3.2,1.5,6.3,1.3,9.5c0,0.5,0.2,1,0.5,1.4
    c1,1.4,1.2,3,1.2,4.7"/>
  <path class="drawn-lines" d="M158,442.1c0.6,1.6,0.2,3.4,0.4,5c0.5,5-0.4,10-0.4,14.9c0,2.9-1,5.7-1.2,8.6c0,0.5-0.1,0.8,0.1,1.3"/>
  <path class="drawn-lines" d="M171.9,493.1c0.2,0.2,0.5,0.5,0.7,0.7c-3,4.3-6.9,7.7-10.5,11.5c-3.2,3.4-6.6,6.7-10.8,8.8
    c-3,1.5-4.9,3.7-6.6,6.4c0,0,0,0.1,0,0.2"/>
  <path class="drawn-lines" d="M206.9,80.6c0.6,1.7,1,3.5,2.2,5c1.1,1.4,2.2,2.8,4.1,3.3c1,0.3,1.7,1.2,2,2.3c0.2,0.7,0.6,1.3,1.1,1.8
    c2.3,2,3.5,4.7,5.2,7.1c2.2,3.1,3.6,6.6,4.5,10.2c0.3,1.2,0.8,2.3,1.2,3.4c0.2,0.6,0.2,1.2-0.2,1.8"/>
  <path class="drawn-lines" d="M204.6,125.7c2.8,4.8,5.1,9.8,7.2,14.9c1.1,2.7,1.9,5.5,2,8.5"/>
  <path class="drawn-lines" d="M198.7,139.4c1.1,2,2.3,4.1,3.4,6.1c2.1,3.8,4.3,7.6,5.8,11.7c0.7,2,1.2,4,1.6,6.1"/>
  <path class="drawn-lines" d="M128.9,251c-1.9,0-3.9,0.2-5.7-0.4c-0.8-0.3-1.5,0-2.2,0.1c-2.7,0.4-5.3,0.4-7.9,0c-1.5-0.2-2.9,0.3-4.3,0.8
    c-1.7,0.6-3.5,0.7-5,1.3c-1.9,0.8-4,0.9-5.6,2.2c-1,0.8-2,1.4-3.2,1.6c-3.1,0.5-4.7,3-6.6,5.1c-1.1,1.3-2.4,2.5-3.6,3.6
    c-1,1-2,1.9-3,3"/>
  <path class="drawn-lines" d="M224.6,161c0.3,3.9,1.5,7.4,3.6,10.6c1.2,1.9,1.8,4,2.5,6.1c0.7,2.1,1.4,4.1,2.7,5.8"/>
  <path class="drawn-lines" d="M30.1,11.4c-0.7-1.5-2.1-1.1-3.2-1c-2,0.1-4.1,0.5-6.1,0.9c-2.8,0.5-5.5,1.3-8.3,1.8c-2.7,0.4-4.8,1.9-7.2,3.1"
    />
  <path class="drawn-lines" d="M207.5,124.1c1.4,1.9,2.2,4.2,3.2,6.3c2.5,4.9,4.1,10.2,5.6,15.5"/>
  <path class="drawn-lines" d="M250.7,248.8c4-1.4,8.5,0.8,10.1,4.7c1.1,2.8,1.4,5.6,1,8.5c-0.1,0.5,0,1.1,0.4,1.4c1.4,1.6,1.2,3.6,1,5.4
    c-0.1,1-0.2,1.9-0.3,2.9c0,2.8,0,5.7-0.7,8.5c-0.3,1.5,0,3.1,0.1,4.7c0.1,2.1,0.1,4.2-0.5,6.3c-0.5,1.9,0.1,4,0.4,5.9
    c0.5,3.9,0,7.7-0.6,11.5c-0.4,2.8-0.9,5.5-2,8.1c-1.1,2.7-0.7,5.7-0.9,8.6c-0.2,3.3-0.6,6.6-0.3,9.9c0.2,2.1-0.1,4.1-0.3,6.1"/>
  <path class="drawn-lines" d="M154.9,458.7c-0.8,4.3-2.1,8.4-3.9,12.4c-1,2.3-1.9,4.7-3.3,6.8c-0.6,1-1.2,2-2.5,2.2c-0.4,0-0.7,0.3-0.9,0.7
    c-1.7,2.4-3.7,4.7-6,6.6c-1.6,1.4-3.1,3.1-4.8,4.4"/>
  <path class="drawn-lines" d="M257.9,342.6c-0.7,0.5-0.5,1.4-0.5,2c-0.1,3.2-0.4,6.4-0.5,9.5c-0.1,2.9,0.6,5.8-0.1,8.6c-0.7,3-0.9,6-0.9,9
    c0,2.8-1.8,5-2.9,7.4c-0.6,1.5-1.8,2.7-2,4.3c-0.1,0.7-0.7,1-1.1,1.4c-2.3,2.4-4.7,4.7-7,7c-0.3,0.3-0.4,0.5-0.4,0.9"/>
  <path class="drawn-lines" d="M224.2,247.9c1.5-0.4,2-1.3,1.6-2.9c-0.4-1.7-1-3.1-2-4.5c-1.3-2-2.8-3.9-3.5-6.3c-0.7-2.6-3-3.9-4.9-5.6"/>
  <path class="drawn-lines" d="M60.5,358.6c-2,0.5-3.5,1.8-5.2,2.7c-1.9,1.1-3.7,1.6-5.8,0.6c-0.5-0.2-0.9-0.2-1.3-0.1c-3,0.9-5.5-0.3-8.1-1.5
    "/>
  <path class="drawn-lines" d="M202.3,139.4c0.8,1.5,1.8,2.7,2.8,4.1c2.4,3.6,4.2,7.4,6,11.2c0.6,1.2,0.6,2.7,0.6,4.1"/>
  <path class="drawn-lines" d="M99.3,9.2c-1.5,0.4-2.9,1.1-4.1,2c-2.5,1.9-5.4,2.7-8.3,3.6c-3.7,1.2-7.6,1.7-11.3,2.9
    c-2.2,0.7-4.3,1.5-6.5,2.1"/>
  <path class="drawn-lines" d="M106.5,33.5c-0.4-1.2-1.5-0.4-1.8-0.3c-5.6,3-11.8,4.2-17.7,6.2"/>
  <path class="drawn-lines" d="M33.1,0.8c-3.1-0.3-5.7,1-8.3,2.5c-3,1.8-6.4,2.9-9.7,4.1c-1.7,0.6-3.5,1.1-5.1,2.1c-1,0.6-2,0.8-3.2,0.6
    c-1-0.1-1.2-0.6-1.4-1.3"/>
  <path class="drawn-lines" d="M28.3,26.2c-1.9,1.6-4.1,2.2-6.5,2.7C17.2,29.8,12.6,30,8,29.2"/>
  <path class="drawn-lines" d="M177.1,423.2c-0.2,3.2,0.6,6.2,0.8,9.4c0.2,3.2,0.8,6.3,0.3,9.5"/>
  <path class="drawn-lines" d="M40.3,2.2c-1.8-0.1-3.4,0.5-5,1.4c-3.5,1.9-7,4-11,4.9c-1.2,0.3-2.5,0.7-3.8,0.6"/>
  <path class="drawn-lines" d="M144,461.4c1.6,3.5,2.8,7.2,3.5,11c0.4,2.2-0.5,4.3-1.6,6.3c-0.2,0.4-0.8,0.7-0.3,1.3"/>
  <path class="drawn-lines" d="M96.8,27.8c-5.2,1.7-10,4.2-15.1,6.2c-1.2,0.4-2.5,0.7-3.4,1.6"/>
  <path class="drawn-lines" d="M240.8,300.9c1.3,2.8,2.2,5.7,3.6,8.5c1,2,2.7,3.2,3.9,4.9c1.4,1.9,3,3.6,3.6,5.9c0.2,0.7,0.7,1.3,0.9,2
    c0.3,0.9,0.6,1.9-0.1,2.7c-0.7,0.7-0.5,1.4-0.5,2.2c0,1.9,0,3.6-1.6,5.1c-0.8,0.7-1.1,2-1.7,3c-0.3,0.4-0.4,1-0.9,1.3
    c-1.5,0.9-1.6,2.3-1.4,3.8c0.2,1.5-0.5,2.7-0.9,4c-0.3,1-1.3,0.8-1.9,1.4c-1.1,1.2-1.9,2.7-3.4,3.5"/>
  <path class="drawn-lines" d="M189.5,378.6c1.2,2.3,1.9,4.8,2.9,7.2c1.4,3.5,3.1,6.9,4.1,10.6"/>
  <path class="drawn-lines" d="M84.4,292.4c0.3,1.9,1.7,2.1,3.2,2.2c0.7,0,1.5-0.2,2,0.7c0.3,0.5,0.9,0.6,1.6,0.8c2.7,0.7,5.2,1.7,7.8,2.6
    c0.3,0.1,0.8,0,1.1,0.1c1.5,0.5,3,0.5,4.4,1.8c1.7,1.6,4.1,2.1,6.7,1.8c2.7-0.4,5.5,0.1,8.3,0.4c2.7,0.4,5.3,0.9,7.9,1.8
    c2.1,0.7,4.1,0.6,6.2,0.4c0.4,0,0.6,0.2,1,0.1c0.2,1.2,1.2,1.2,1.9,1c1.5-0.4,3.2-0.2,4.3-1.6"/>
  <path class="drawn-lines" d="M131,201.7c-1.2-0.9-3-0.8-4.2-2.4c2.5-0.4,4.5,0.5,6.6,1.2c2,0.7,3.8,1.7,5.9,2c0.7,0.1,1.4,0.6,2,1
    c2.2,1.4,5,1.6,7.1,3.6c1.3,1.3,3,2.2,4.5,3.3c0.3,0.2,0.8,0.4,1.1,0.6c0.7,0.5,0.5-0.3,0.8-0.4"/>
  <path class="drawn-lines" d="M157.3,521.3c-4.2,2.3-6.9,6.1-9.5,9.9c-1.2,1.7-2.3,3.5-3.4,5.2c-0.2,0.3-0.4,0.7-0.4,1.1"/>
  <path class="drawn-lines" d="M173.6,101.9c0.1,0.9-0.4,1.6-0.7,2.3c-0.8,1.8-0.3,3.1,1.6,3.2c1.1,0.1,1.8,0.6,2.1,1.5c0.9,3,2.9,5.3,4.6,7.9
    c0.9,1.5,0.9,3.4,2.3,4.7c-0.4,2.8,1.5,4.8,2.8,7c1.3,2.3,2.4,4.7,3.5,7.1c0.1,0.2,0.1,0.4,0.2,0.5"/>
  <path class="drawn-lines" d="M127.1,199.3c-2.5-0.6-4.9,0.2-7.4,0.4c-0.4,0-0.8,0.1-1.3,0.1c-4.5,0.2-9,0.7-13.5,0.5
    c-2.5-0.1-4.4,1.1-6.7,1.4c-2.8,0.4-5.1,1.9-7.6,3.1c-1.6,0.8-2.2,2.5-3.6,3.4"/>
  <path class="drawn-lines" d="M77,13.6c-0.7-0.1-1.3,0.4-1.8,0.7c-6.2,5-13.3,8.5-20.3,12c0,0-0.1,0-0.2,0"/>
  <path class="drawn-lines" d="M52.6,21.7c-0.5,1.2-1.9,1.5-2.9,1.9c-3.7,1.6-7.5,3.1-11.3,4.5c-1.1,0.4-1.8,1.4-3,1.3"/>
  <path class="drawn-lines" d="M160.2,53c-1.4,2.6-1.9,5.6-3,8.3c-0.7,1.6-1.6,3-2.4,4.5c-0.4,0.8-0.8,1.7-1.1,2.5c-0.7,1.6,0.1,1.4,1.1,1.3"
    />
  <path class="drawn-lines" d="M206.4,211c2.7,3.6,5.7,6.9,8.1,10.8c1,1.7,2.1,3.4,2.5,5.4"/>
  <path class="drawn-lines" d="M103.3,30.7c-2.8-1-4.7,1-6.8,2c-3.5,1.6-6.6,3.9-10.4,4.7"/>
  <path class="drawn-lines" d="M225.5,267.9c1.5,2.6,2.7,5.2,3.4,8.2c-0.5,0.2-1,0.4-1.7,0.7c2,5,4.7,9.7,6.1,14.9"/>
  <path class="drawn-lines" d="M250.8,273.3c0.4-1,0.1-1.8-0.5-2.5c-2.9,0.2-4.2,2.5-2.7,4.8c1,1.6,1.1,3.4,1.3,5.1c0.4,2.8,1.3,5.4,2.1,8
    c0.6,2.1,1.1,4.1,1.1,6.3c0,2.4,1.2,4.5,1.6,6.8c0.6,3,0.9,6.1,1.1,9.2c0,0.3,0.2,0.5,0.4,0.7"/>
  <path class="drawn-lines" d="M206.6,139.2c3,4.4,5.1,9.3,6.9,14.4c0.2,0.7-0.7,1.8,0.7,2.2"/>
  <path class="drawn-lines" d="M193.1,134c1.8,5.9,5.2,10.9,8.8,15.8"/>
  <path class="drawn-lines" d="M25.4,310.2c-0.8,3.1-1.6,6.1-2.5,9.2c-0.6,2.1-1.5,4.2-1.4,6.5"/>
  <path class="drawn-lines" d="M96.7,360.2c-1.5,0-3,0-4.5,0.3c-2.8,0.7-5.8,1.1-8.6,1.5c-1.2,0.2-2.5,0.2-3.8,0.5c-1.9,0.4-3.7-0.5-5.6-0.3"
    />
  <path class="drawn-lines" d="M119.9,417.1c0.2-1.5,1.2-0.8,2.1-0.7c0.1,0.6,0.4,1.2-0.3,1.6c-0.3,0.2-0.9,0.1-1.2-0.3
    c-0.6-0.7-1.5-0.4-2.2-0.3c-4.4,0.4-8.8,1.8-13.3,1.4c-3.3-0.3-6.4,0.6-9.6,1.2c-1.7,0.3-3.4,0.7-5-0.3"/>
  <path class="drawn-lines" d="M69.5,14.8c-1.2-0.5-1.9-1.5-2.9-2.3c-2.6-2.2-5.5-3.7-9-2.8c-1.6,0.4-3.2,0.9-4.5,1.9
    c-0.6,0.4-0.4-0.2-0.5-0.4"/>
  <path class="drawn-lines" d="M82.1,215.2c3,0.7,6.1,0.7,9.2,1.3c2.3,0.4,4.6,0.3,6.8,0.3c0.7,0,1.4,0,2-0.4"/>
  <path class="drawn-lines" d="M40.7,10c-3.3,2.2-6.6,4.4-10.4,5.6c-0.8,0.2-1.5,0.7-2.4,0.8c-0.6,0.1-1.5,0.4-2-0.5"/>
  <path class="drawn-lines" d="M103.3,291.7c2.3,1.6,4.8,3,7.4,3.4c1.8,0.3,3.6,1.6,5.8,0.8c0.6-0.2,1.8,0.4,2.7,0.6c1.8,0.5,3.6,0.1,5.4,0.1
    c0.9,0,1.7,0,2.5,0.4"/>
  <path class="drawn-lines" d="M169,60.5c-0.5,4.4-1.4,8.7-2.7,13c-0.2,0.6-0.3,1.2-0.3,1.8"/>
  <path class="drawn-lines" d="M170.9,265.6c-1-2.2-2.9-3.7-4.8-4.9c-2.6-1.7-4.4-4.7-7.8-5c-0.5-0.1-0.9-0.7-1.5,0c-0.2,0.2-1,0.2-1.4-0.3
    c-0.9-1.1-2.1-1.3-3.4-1.5c-1.7-0.2-3.2-1.1-4.9-1.5c-3.2-0.7-6.4-1.6-9.7-1.6c-1.5,0-3-0.5-4.5-0.7c-0.6-0.1-1.3-0.1-2,0.2"/>
  <path class="drawn-lines" d="M81,415.3c1-0.9,2.3-0.7,3.4-1.1c0.5-0.2,1.3-0.3,1.4-0.9c0.2-0.7-0.5-1-1-1.5c-1.1-1-2.6-1.4-3.8-1.5
    c-3.9-0.5-7.9-1.1-11.9-0.8c-1.7,0.2-3.5,0.3-4.7,2"/>
  <path class="drawn-lines" d="M228.7,124.6c0.7,2.7,2.2,4.7,4.1,6.7c1.7,1.9,3.3,4,2.8,7c-0.3,1.6,0.4,3.1,1,4.5c0.5,1.1,1.5,2.2,1.3,3.6"/>
  <path class="drawn-lines" d="M43.2,10c-1.4,0.4-2.5-0.7-3.8-0.9c-2.2-0.4-4.1,0.7-6.1,1.3c-0.5,0.1-1,0.3-1.4,0.5c-1.1,0.5-2.1,1.2-3,2.2
    c-1.7,1.8-3.8,3.2-5.9,4.6c-3.2,2.1-6.4,4.3-9.9,6c-0.4,0.2-1.2,0.4-0.9,1.2"/>
  <path class="drawn-lines" d="M158.7,49.2c-3,4.7-6.9,8.9-9.7,13.8c1.4,0.9,2.1,0.1,2.7-0.8"/>
  <path class="drawn-lines" d="M104.4,227.4c-6.3,1.3-12.4,3.1-18.3,5.4"/>
  <path class="drawn-lines" d="M81.7,288.8c0.3-1.3,1.4-1.1,2.3-1c0.8,0.1,1.7,0.2,2.5,0.7c1.3,0.8,2.6,1.1,4.1,0.8c1.3-0.3,2.5,0.6,3.7,1
    c1.3,0.4,2.6,0.6,3.8,0.4c0.7-0.1,1.2,0.7,1.8,0.1"/>
  <path class="drawn-lines" d="M170.9,28.5c2,0.9,4.1,1.6,5.8,3.2c1.5,1.5,1.6,1.8,0.3,3.4c1.3,2.9,4,4.5,6.1,6.7c0.3,0.3,0.7,0.4,0.9,0.7
    c1.1,2.1,2.6,3.6,5.1,4c0.7,0.1,0.7,0.8,0.9,1.3c1.4,2.9,3.3,5.6,5.4,8.1"/>
  <path class="drawn-lines" d="M134.4,305.9c-1,0.7-2,0.8-3.2,0.8c-1.6,0.1-3-0.2-4.5-0.2c-5,0-9.6-1.2-14.2-2.8c-0.7-0.2-1.5-0.1-2-0.7"/>
  <path class="drawn-lines" d="M100.8,357.5c-2.2,0.3-4.4,0.6-6.7,0.9c-3.1,0.5-6.2,0.9-9.3,1.5c-2,0.4-4,0.6-5.9,1.4"/>
  <path class="drawn-lines" d="M93.4,318c3.6,4.7,7.3,9.3,12.4,12.6"/>
  <path class="drawn-lines" d="M257.7,301.8c-0.3-2.3,0.9-4.4,0.7-6.7c-0.1-0.9,0-1.8-0.3-2.7c-0.8-2.4,0.3-4.9,0.2-7.4
    c-0.2-5.2,1.1-10.3,0.6-15.5c-0.2-1.8,0.1-3.8-0.5-5.6c-0.3-0.8-0.7-1.3-1.8-1.3c-2.7-0.1-4.8,0.9-6.3,3.1c-0.6,0.9-1.2,1.7-2.3,2"
    />
  <path class="drawn-lines" d="M154.8,14.6c-0.6,0.3-1.1-0.4-1.4-0.6c-2.4-1.6-2.9-1.6-4.9,0.1c-0.1-0.1-0.3-0.1-0.3-0.2
    c-0.8-3.1-0.8-3.2-4.3-3.4c-1.8-0.1-3.1-0.8-4.3-2c-0.9-1-1.9-1.9-3.4-1.8c-0.9,0.1-1.1-0.7-1.6-1.1c-0.8-0.5-1.5-1.2-2.4-0.1
    c-1.7-4.6-5.2-2.2-8.1-2.2C123.1,1,123.1,1,120.6,1c-0.8,0-1.7-0.2-2.5,0.2"/>
  <path class="drawn-lines" d="M213.8,125c0.6,1.1,1,2.3,0.7,3.6c-0.2,1,0.4,1.7,0.7,2.5c2.1,4.7,4.2,9.3,5.2,14.4"/>
  <path class="drawn-lines" d="M117.2,209.4c-0.7,0.7-1.6,0.8-2.5,0.9c-3.2,0.4-6.3,1.4-9.3,2.5c-0.8,0.3-1.8,0.6-1.1,1.8"/>
  <path class="drawn-lines" d="M149.5,51.2c-1.2,3.2-3,6.2-4.5,9.3c-0.3,0.5-0.3,1.2-0.6,1.7c-0.2,0.3-0.1,0.8,0.5,0.9
    c0.6,0.1,0.6-0.4,0.7-0.7"/>
  <path class="drawn-lines" d="M184.3,266.1c-2.9,1-5.8,0.1-8.6-0.4c-1.1-0.2-2.4,0.1-3.5-0.4c-0.1,0-1.2-0.3-1.3,0.6"/>
  <path class="drawn-lines" d="M89.1,327.7c2.1,1.6,3.9,3.5,6.1,4.9c1.5,1,2.2,2.9,3.6,4.1c0.8,0.7,0.9,2,2,2.5c0.1,0,0.2,0.1,0.2,0.2
    c0.8,3.7,4.1,6.3,4.6,10.2c0.2,1.9,1.3,3.7,1.1,5.8c-0.1,0.4-0.3,0.7-0.4,1.1"/>
  <path class="drawn-lines" d="M155.1,350c0.9,0.3,1.8,0.5,2.7,0.4c3.3-0.4,6.3,0.9,9.4,1.8c2,0.6,4.1,1,5.9,2.2"/>
  <path class="drawn-lines" d="M217.4,189.1l4.9,13"/>
  <path class="drawn-lines" d="M194.2,56.6c1.1,3.3,3.2,5.8,5.6,8.3c1.6,1.7,2.5,3.6,3.2,5.8c0.4,1.3,1.6,2.1,2.1,3.3c0.1,0.3,1.3,1.3-0.3,1.4
    "/>
  <path class="drawn-lines" d="M201.9,320.5c0.9,3.7,1.5,7.5,2,11.3c0.1,0.4,0.3,0.8,0.5,1.3"/>
  <path class="drawn-lines" d="M115.2,218.6c-6.9,0.6-13.4,3-20,4.8c-1.1,0.3-2.3,0.4-3.4,0.6"/>
  <path class="drawn-lines" d="M170.4,67.2c-0.5,2.6-1.6,5.1-2,7.7c-0.2,1.3-0.6,2.7-0.5,4.1c0,0,0.1,0.1,0.2,0.1c0.1,0,0.2-0.1,0.4-0.2"/>
  <path class="drawn-lines" d="M39.2,65.9c-4.8,2.9-9.6,5.7-14.6,8.1c-0.3,2.3-1.3,4.3-3.8,5.4"/>
  <path class="drawn-lines" d="M163.9,56.4c0,1.8-0.5,3.5-1.3,5c-1.3,2.4-2.4,4.9-3.6,7.4"/>
  <path class="drawn-lines" d="M189.5,385.3c-0.6,0.9-1.2,1.7,0.1,2.8c0.8,0.6,1.4,1.8,1.5,3c0.3,2,1.6,3.5,2.4,5.2c1.1,2.2,0.9,4.8,1.3,7.2
    c0.5,2.8,1.2,5.6,1.5,8.5c0.1,1.2,0.3,2.5,0.8,3.6"/>
  <path class="drawn-lines" d="M29.5,422c-1-0.2-1.9,0-2.9,0.1c-2.4,0.4-4.8,0.4-6.6-1.6c-0.7-0.8-1.5-0.6-2.4-0.4"/>
  <path class="drawn-lines" d="M176.4,69.6c0.3,3-0.5,5.9-1.3,8.8c-0.3,1.1-0.1,2.4-1,3.3"/>
  <path class="drawn-lines" d="M149.7,218.8c-2.9-0.3-5.9-0.7-8.8-0.9c-2.4-0.1-4.9-0.3-7.4,0.1"/>
  <path class="drawn-lines" d="M198.5,422.4c0,3.3,0,6.6,0,9.9c0,0.8,0.1,1.5,0.4,2.2"/>
  <path class="drawn-lines" d="M42.3,21.1c-2.1,1-3.3,3.1-5,4.6c-3.1,2.8-6.6,4.3-10.7,4.8"/>
  <path class="drawn-lines" d="M120.9,346.4c1.7,1.6,3.2,3.4,4.6,5.2c1.4,1.8,3.2,3.2,4.8,4.8c0.4,0.4,0.4,0.8,0.7,1.1"/>
  <path class="drawn-lines" d="M20.2,235.5c-3.3-2-7-3-10.4-4.7c-0.8-0.4-0.9,0.5-1.5,0.4"/>
  <path class="drawn-lines" d="M35.1,24.4c-1.2,0.7-2.2,1.9-3.8,0.8c-0.3-0.2-0.8,0-1.2,0.1c-3.5,0.9-7.1,1.7-10.6,2.5c-3,0.7-6,0.4-9,0.2
    c-1.1-0.1-1.9-0.9-2.9-1.2"/>
  <path class="drawn-lines" d="M25.6,296.7c-0.2,4.2-1.5,8.2-1.6,12.4c0,0.2,0.1,0.4,0.2,0.5"/>
  <path class="drawn-lines" d="M23.2,243.8c-0.3,2.8-0.2,5.7-1.2,8.5c-0.5,1.4-1.4,2.3-2.4,3.2"/>
  <path class="drawn-lines" d="M30.8,66.1c-1.8,0.4-3.3,1.4-4.9,2.3c-2.2,1.3-4.5,2.4-6.8,3.6"/>
  <path class="drawn-lines" d="M100.2,298.5c-0.1-1.8-1.5-2.4-2.9-2.9c-2.6-0.9-5.1-2.1-7.9-1.1"/>
  <path class="drawn-lines" d="M30.4,389.8c0.4,3.5,0.6,6.9,0.5,10.4c-0.1,1.9-0.4,3.7-0.4,5.6c0,0.8-0.1,1.8,0.9,2.3"/>
  <path class="drawn-lines" d="M158.4,516c0.4,0.1,0.6,0.3,0.2,0.5c-2.3,1-3.9,3.1-6.1,4.3c-1.5,0.9-2.9,1.9-4.3,2.9"/>
  <path class="drawn-lines" d="M84.6,444c-1.7,0.2-3.3,0.9-5,0.4c-1-0.3-1.7,0.5-2.7,0.6c-2.3,0.2-4.6,0.2-6.8,0.2c-2.6,0-5,1-7.5,1.2
    c-5.5,0.5-10.9,1.5-16.4,1.9c-1.1,0.1-2.1,0.5-3.2,0.3"/>
  <path class="drawn-lines" d="M25.6,356.5c-0.7-1.7-2.2-0.8-3-0.6c-3.2,0.8-6.4,1.1-9.6,1.5"/>
  <path class="drawn-lines" d="M88.7,504.4c1.4,2,3.7,2.2,5.8,2.6c1.7,0.3,3.5,0.6,5.2,1"/>
  <path class="drawn-lines" d="M85.5,224.5c-2.5-0.9-5.2-0.9-7.7-0.3c-1.1,0.3-2.4,0.1-3.4,1c-0.3,0.3-0.8,0.4-0.9,0.9"/>
  <path class="drawn-lines" d="M184.3,425.6c-0.4,1.9-0.6,3.9-0.3,5.8c0.5,3.4-0.1,6.6-0.4,9.9c-0.1,1.5,0.1,3.2-1,4.5"/>
  <path class="drawn-lines" d="M236.4,185.3c0.8,0.6,0.8,1.5,1.1,2.3c1.2,2.6,2.3,5.2,3.9,7.6c0.2,0.3,0.3,0.8,0.7,0.9
    c1.1,0.1,1.3,0.9,1.5,1.8c0.1,0.7,0.4,1.3,0.7,2"/>
              </svg>
            </div>
            <div class="anim-splash">
              <svg id="icc-svg" width="541.29" height="315.03" viewBox="0 0 541.29 315.03">
              <path class="icc-lines" d="M273.35,23.13c2.15.11,3.82,1.48,5.75,2.19,3.94,1.45,7.8,3.1,11.71,4.64,4.72,1.85,9.75,2.8,14.39,4.88,1.96.88,3.85,1.9,5.77,2.87,3.36,1.68,6.89,2.73,10.62,3.25,7.31,1.02,14.41,2.71,21.09,6.05,3.13,1.56,6.5,2.64,9.87,3.68,3.43,1.05,6.61,2.69,9.75,4.43,2.36,1.31,4.84,2.49,7.38,3.41,4.26,1.55,8.36,3.47,12.6,5.04,3.04,1.12,6.14,2.11,9.18,3.24,2.46.91,4.78,2.09,7.19,3.09,4.01,1.65,8,3.35,12.07,4.84,5.97,2.19,11.95,4.37,18.01,6.26,2.91.91,5.84,1.7,8.62,2.95,2.18.98,4.39,1.87,6.5,3.03,3.86,2.14,8.15,3.39,12.02,5.46,5.26,2.81,10.85,4.73,16.39,6.83,2.6.99,5,2.49,7.59,3.53,5.3,2.13,10.67,4.09,16.01,6.15,4.43,1.71,8.96,3.1,13.36,4.95,5.45,2.3,10.63,5.23,16.33,6.97.73.22,1.41.49,2.18.49"/>
    <path class="icc-lines" d="M267.95,1c-.37,1.38-1.72,1.67-2.72,2.11-5.3,2.35-10.56,4.84-15.76,7.37-5.12,2.49-10.19,5.17-15.19,7.96-3.63,2.03-7.33,3.93-10.97,5.95-3.92,2.18-7.82,4.4-11.7,6.66-2.37,1.38-4.59,3.06-7.05,4.27-5.68,2.79-11.05,6.1-16.56,9.18-4.49,2.51-9.17,4.7-13.81,6.94-5.2,2.51-10.55,4.68-15.72,7.24-6.38,3.16-12.6,6.6-18.86,9.98-4.7,2.54-9.19,5.49-13.9,8.03-4.08,2.19-8.05,4.55-12.08,6.8-3.57,1.99-7.12,4.12-11.11,5.32-1.89.57-3.2,2.23-5.02,3.08-3.57,1.67-7.12,3.46-10.43,5.58-2.97,1.9-6.41,2.93-9.17,5.04-2.68,2.05-5.8,3.14-8.74,4.51-5.84,2.72-11.24,6.28-17.37,8.49-4.25,1.53-8.09,4.18-12.1,6.36-1.43.78-2.91,1.36-4.48,1.86-1.6.51-3.15,1.35-4.54,2.42-1.1.85-2.15,1.97-3.76,1.82-.06,0-.15-.03-.18,0-2,2.07-4.98,2.36-7.27,4.05-2.13,1.57-4.39,3.23-6.97,3.99-2.67.78-5.03,2.27-7.72,2.94-.92.23-1.04.87-1.13,1.79-.28,2.71-.84,5.4-1.19,8.1-.27,2.08-.63,4.17-.38,6.29.09.76-.05,1.4-1.07,1.26"/>
    <path class="icc-lines" d="M272.09,21.51c-.24-.06-.58-.25-.71-.16-4.03,2.92-8.74,4.63-12.83,7.49-4.6,3.21-9.94,5.19-14.74,8.12-3.23,1.98-6.55,3.82-9.9,5.58-3.49,1.83-7.13,3.39-10.59,5.27-3.56,1.92-7.46,3.09-10.97,5.05-4.93,2.75-10,5.23-14.79,8.23-6.89,4.3-14.14,7.96-21.16,12.01-5.05,2.91-10.18,5.71-15.23,8.62-2.73,1.57-5.5,3.11-8.39,4.45-4.5,2.09-8.79,4.6-13.17,6.95-3.78,2.03-7.66,3.91-11.31,6.16-4.22,2.6-8.61,4.87-13,7.13-4.39,2.26-8.92,4.24-13.29,6.53-3.09,1.62-6.02,3.55-9.02,5.37-1.63.99-3.24,2.02-5.04,2.7-1.45.55-2.73,1.4-3.96,2.33-1.98,1.5-4.47,1.85-6.66,2.88-3.18,1.5-6.48,2.79-9.5,4.56-2.74,1.6-5.65,2.88-8.32,4.6-2.78,1.8-5.62,3.51-8.46,5.22-2.43,1.46-4.33,3.77-7.2,4.48-.88.22-.81.81-.91,1.46-.75,4.68-1.53,9.36-2.33,14.03-.58,3.35-1.35,6.66-1.45,10.07,0,.24-.23.48-.35.72"/>
    <path class="icc-lines" d="M540.29,103.36c-1.68.01-3.28-.54-4.65-1.31-4.48-2.52-9.37-3.88-14.26-5.1-6.61-1.65-12.88-4.1-19.07-6.88-4.58-2.06-9.23-3.98-13.85-5.95-4.01-1.71-7.8-3.94-12.06-5.04-2.94-.76-5.87-1.58-8.82-2.33-3.27-.83-6.5-1.69-9.56-3.2-3.15-1.56-6.51-2.7-9.69-4.2-5.14-2.43-10.17-5.11-15.32-7.51-5.95-2.76-11.86-5.68-18.35-7.03-2.39-.5-4.79-.88-7.04-1.95-.96-.46-2.01.38-3.04.32-2.32-.15-4.06-1.61-5.93-2.71-4.27-2.52-8.74-4.6-13.33-6.46-3.17-1.29-6.34-2.56-9.52-3.83-5.28-2.1-10.58-4.15-15.83-6.31-5.2-2.13-10.68-3.37-15.91-5.39-3-1.16-6.25-1.88-9.3-3.02-3.99-1.49-7.98-2.95-11.91-4.59-5.93-2.48-11.97-4.68-17.62-7.78-1.94-1.06-4.21-1-6.3-1.59-3.61-1.03-7.14-2.23-10.62-3.61-.4-.16-1.18-.72-1.43.33"/>
    <path class="icc-lines" d="M442.55,160.03c-.37,2.94.8,5.57,1.63,8.27.1.32.3.66.54.89,2.63,2.56,3.74,5.52,2.71,9.19-.94,3.35-.92,6.87-1.71,10.27-.42,1.78.19,3.82.45,5.73.47,3.44,1.57,6.74,2.44,10.09.5,1.92.7,4.01-.46,5.93-.72,1.19-.21,2.5-.2,3.77.04,3.43-1.56,6.21-3.59,8.82-2.23,2.88-4.7,5.47-7.71,7.61-1.97,1.39-4.14,2.03-6.35,2.4-2.81.47-5.14,1.88-7.52,3.17-3.14,1.7-6.27,2.94-9.91,3.29-4.21.41-8.42-.06-12.61.43-1.31.15-2.58-.1-3.6-1.06"/>
    <path class="icc-lines" d="M264.17,110.02c-.19-2.25.88-4.29,1.1-6.47.33-3.29.85-6.6.48-9.89-.39-3.43-1.73-6.62-3.6-9.51-.51-.79-1.07-1.57-1.56-2.38-2.07-3.36-5.71-4.57-8.84-6.46-3.61-2.17-7.65-2.58-11.68-2.11-3.86.45-7.3,2.31-10.25,4.82-4.68,3.97-6.64,9.62-8.83,15.09-.03.08.11.24.17.36"/>
    <path class="icc-lines" d="M180.11,124.05c-3.72.22-7.09,1.72-10.41,3.13-4.44,1.88-6.69,5.92-8.25,10.21-1.02,2.81-2.54,5.34-3.69,8.06-2.02,4.82-3.84,9.73-5.85,14.53-2.22,5.29-4.43,10.64-7.88,15.41-2.03,2.81-2.4,6.33-1.85,10.02.42,2.81,1.08,5.54,1.82,8.24.74,2.67,2.22,5.13,3.14,7.77,2.05,5.93,5.76,10.89,9.2,16,.61.91,1.5,1.5,2.33,2.17,1.76,1.41,3.62,2.7,5.26,4.28,1.21,1.17,2.67,2.05,4.48,2"/>
    <path class="icc-lines" d="M212.87,110.74c-3.3-1.43-6.38-3.34-10.07-3.85-1.12-.15-2.07.23-3.06.35-1.11.13-1.26,1.69-.76,2.79,1.69,3.66,4.73,5.98,7.72,8.5,5.3,4.47,10.24,9.4,13.48,15.63,1.6,3.07,2.67,6.45,3.5,9.86.74,3,1.64,5.94,2.21,8.99.34,1.8.72,3.62.74,5.41.03,1.73.55,3.34.72,5.03.21,2.16.47,4.37,1.12,6.5.2.67.5,1.15.95,1.6"/>
    <path class="icc-lines" d="M383.87,139.52c1.87-1.91,4.05-3.43,6.49-4.47,1.57-.67,2.8-1.82,4.31-2.55,1.41-.68,2.36-.44,3.42.9.13.17.21.48.37.52,3.89.93,6.55,4.25,10.42,5.28,3.15.83,6.24,1.67,9.55,1.61,6.61-.12,12.82,1.66,18.49,4.91,2.09,1.2,3.24,3.73,3.94,6.05.74,2.48,1.37,5.06,1.34,7.71"/>
    <path class="icc-lines" d="M427.97,233.96c.69.59.61,1.41.53,2.16-.41,3.7.23,7.44,1.45,10.73,1.35,3.65,2.06,7.46,3.53,11.07,1.16,2.84,1.66,5.96,2.41,8.97.58,2.33.99,4.7,1.65,7.01.78,2.73,1.23,5.51,1.82,8.27.54,2.49.04,4.85-.46,7.2-.3,1.42-1.64,2.03-3.01,2.35-1.05.25-.82-.99-1.44-1.26"/>
    <path class="icc-lines" d="M377.03,158.59c.8-.84,1.67-1.59,2.78-1.99.5.68.42,1.51.24,2.16-1.13,4.14-1.55,8.42-2.43,12.61-.74,3.47-.7,7.05-.58,10.6.13,4.02.12,8.05.07,12.05-.08,6.19.53,12.35.29,18.53-.06,1.68-.01,3.36,0,5.04,0,.7-.14,1.49.9,1.62"/>
    <path class="icc-lines" d="M97.49,171.54c.57,1.32-.44,2.49-.38,3.78.13,2.52-.51,4.98-1.12,7.36-1.19,4.64-2.04,9.33-2.87,14.04-.91,5.15-1.5,10.34-2.36,15.5-.6,3.6-.81,7.3-1.19,10.96-.53,5.1-1.56,10.11-2.65,15.12-.76,3.5-2.11,6.93-2.44,10.44-.47,4.94-1.69,9.71-2.57,14.54-.83,4.56-1.47,9.15-2.43,13.69-.98,4.59-1.72,9.23-2.56,13.85-.35,1.92-.94,3.82-1.21,5.77-.34,2.45-.56,4.92-.93,7.37-.27,1.81-.64,3.62-1.08,5.39-.39,1.55-.52,3.1-.52,4.68"/>
    <path class="icc-lines" d="M388.19,107.32c-.64-.92-.84-2.05-.84-3.06,0-1.55-.97-1.97-2.05-2.12-2.38-.34-4.81-.99-7.18-.19-2.08.7-4.28,1.01-6.29,1.82-4.72,1.89-9.7,2.73-14.6,3.91-3.65.87-7.3,1.77-10.98,2.49-2.1.41-4.14,1.15-6.24,1.2-.6.77.28,1.57-.23,2.25"/>
    <path class="icc-lines" d="M467.39,161.65c.53,1.22.29,2.51.37,3.78.17,2.76.63,5.49.55,8.28-.04,1.26.1,2.52.26,3.79.23,1.82.4,3.76-.29,5.56-1.01,2.66-.4,5.4-.47,8.1-.02.81.13,1.69.09,2.52-.24,5.59.33,11.15.45,16.73.11,4.74-.1,9.48.13,14.21.27,5.64.46,11.28.81,16.92.16,2.67-.12,5.39-.09,8.09.03,2.46-.22,4.91.12,7.38.3,2.25.14,4.55.24,6.83.15,3.3.31,6.6.54,9.89.59,8.28,2.52,16.47,1.93,24.82-.3,4.21.28,8.4.04,12.6"/>
    <path class="icc-lines" d="M362.09,79.97c.56.69,1.35.4,1.98.33,3.59-.42,6.7.97,9.69,2.6.91.5,1.83,1.02,2.73,1.56,3.44,2.1,5.42,5.26,6.67,8.99.55,1.64,1.19,3.22,1.13,5.04-.04,1.19.62,2.29,2.1,2.32.83.02,1.62.36,2.31.63,1.66.66,2.06,2.93.69,4.74-.82,1.09-1.4,2.38-2.46,3.28"/>
    <path class="icc-lines" d="M417.53,161.83c-.86,1.54-.11,3.04.22,4.49.62,2.76.45,5.55.23,8.28-.19,2.26-.39,4.41.04,6.67.3,1.62.41,3.44-.01,5.01-.92,3.37.18,6.24,1.76,8.97.56.96.49,1.65.08,2.37-.92,1.64-1.1,3.28-.52,5.05.52,1.61.78,3.12-.67,4.54-.57.56-.9,1.64-.89,2.47.03,1.35-.41,2.13-1.67,2.54-.4.13-.88.38-1.06.72-1.29,2.43-3.8,2.56-5.98,2.97-2.15.4-4.03,1.13-5.52,2.64-.63.64-1.21.56-1.84.48-2.05-.25-4.12-.28-5.73,1.13-1.59,1.4-3.13.35-4.71.35-.99,0-2.15-.46-2.88.69"/>
    <path class="icc-lines" d="M269.03,224.61c-1.64,4.39-4.13,8.37-6.14,12.58-2.26,4.74-5.39,8.96-8.29,13.3-1.81,2.71-3.61,5.43-5.67,7.98-1.36,1.68-1.75,4.05-3.3,5.71"/>
    <path class="icc-lines" d="M248.69,191.33c.6-.78,1.21-.3,1.8,0,2.41,1.26,4.63,2.84,7,4.18,4.05,2.29,8.08,4.6,12.45,6.25,2.98,1.12,4.38,3.26,4.13,6.48-.27,3.43-1.46,6.59-2.83,9.73-.87,2.01-1.36,4.18-2.02,6.28"/>
    <path class="icc-lines" d="M432.29,291.71c1.61.04,1.94-1.35,2.47-2.37.31-.61-.22-1.04-1.01-1.28-1.78-.53-3.78-1-5.38-.55-2.81.79-5.72.66-8.51,1.46-6.34,1.81-12.83,2.51-19.43,2.41-4.26-.06-8.52.31-12.78.29-2.81-.01-5.67.02-8.29-1.39-2.67-1.44-4.91-3.16-5.82-6.29-.25-.85-.56-1.89-1.38-2.54"/>
    <path class="icc-lines" d="M271.73,231.44c.29,3.03,1.26,5.93,1.47,8.99.32,4.56,1.34,9.04,1.11,13.68-.14,2.8.79,5.59.71,8.45-.02.68.14,1.44.1,2.16-.12,2.63.49,3.78,2.91,4.47,2.24.63,4.54,1.07,6.83,1.45,4.02.67,7.85,1.98,11.7,3.24,2.54.83,5,1.85,7.37,3.08,1.1.57,2.23.74,3.43.71"/>
    <path class="icc-lines" d="M39.35,172.26c-.22.53-.56.99-.55,1.62.05,4.06-.93,8.03-1.24,12.05-.34,4.37-.57,8.76-.92,13.13-.22,2.76-.65,5.51-.87,8.28-.34,4.4-1.36,8.72-1.67,13.13-.21,3.01-.55,6.02-1.09,8.99-.77,4.24-1.75,8.43-1.79,12.78-.03,3.82-1.1,7.53-1.47,11.33-.16,1.62.11,3.27-.45,4.88-.14.4-.24.68-.59.88"/>
    <path class="icc-lines" d="M227.99,165.06c1.38-.03,1.96.6,1.63,1.98-.04.17-.06.38,0,.54.75,1.95.74,3.86-.16,5.77-.16.34-.13.87,0,1.24.83,2.4.29,4.98,1.03,7.39.12.41.31,1-.41,1.47-2.81-1.04-6.17-.97-9.1-2.55-.87-.47-1.84.06-2.48.42-1.48.82-2.94,1.77-4,3.17"/>
    <path class="icc-lines" d="M220.07,94.19c-2.85-.07-5.63.01-7.94,2.14-1.95,1.79-2.86,3.98-2.42,6.48.23,1.33,1.02,2.73,1.95,3.75,2.79,3.08,6.09,5.37,10.2,6.57,3.63,1.06,7.09,2.71,10.8,3.6.41.1.84.23,1.27.31,1.71.29,3.27.57,4.21,2.48.98,2,2.46,1.89,3.71.03"/>
    <path class="icc-lines" d="M257.87,120.27c4.2-.41,8.31-1,11.92-3.55,1.08-.76,1.85-1.61,2.29-2.75.77-1.99-.6-4.88-2.88-5.3-1.92-.36-3.49-1.86-5.42-1.43-.68-.44-.72-.97-.69-1.54"/>
    <path class="icc-lines" d="M211.07,106.24c-1.47.53-2.5-.43-3.59-1.09-1.92-1.15-3.8-2.17-6.1-2.63-2.08-.42-3.7.27-5.02,1.26-6.04,4.53-10.59,10.4-14.46,16.84-.54.91-.91,1.85-1.07,2.88"/>
    <path class="icc-lines" d="M362.27,78.71c-.18,1-.67,1.63-1.8,1.44-.22-.04-.56.22-.56-.36,0-1.78-1.16-1.55-2.32-1.26-1.44.36-2.88.74-4.32,1.07-2.48.58-2.72.95-2.13,3.56-2.99.89-5.19,2.89-6.85,5.45-2.41,3.7-4.83,7.35-5.14,12.04-.14,2.15.47,4.19.29,6.3-.1,1.17-.02,2.17-1.44,2.76-.97.4-1.85,1.29-2.23,2.4.83,1.07,2.82,1.69,5.98,1.86"/>
    <path class="icc-lines" d="M259.49,170.82c-.24,2.94-.54,5.87-.71,8.82-.27,4.61-.81,9.22-.55,13.85"/>
    <path class="icc-lines" d="M146.09,171.72c-1.23-1.8-3.16-1.14-4.67-.99-2.32.24-4.56.05-6.85-.02-3.11-.1-6.24.29-9.36.47-6.41.36-12.8,1.58-19.27.58-.86-.13-1.81.15-2.69.34-1.75.38-3.48-.04-5.22,0"/>
    <path class="icc-lines" d="M4.07,155.35c5.69,1.89,10.76,5.12,16.2,7.55,2.74,1.22,5.67,1.88,8.32,3.45,3.32,1.98,6.95,3.43,10.37,5.24.55.29,1.12.39,1.65.67"/>
    <path class="icc-lines" d="M371.09,219.93c1.05-.53,2.2-.21,3.03.24,1.62.9,3.36,1.35,5.06,1.94.5.18,1.06.31,1.43.12,1.89-.95,4.03-.6,5.94-1.27.68-.24,1.24-.03,1.61.25,2.66,2,4.42,4.54,5.32,7.86.94,3.46-.17,6.63-.61,9.91-.06.47-.86.62-.54,1.26"/>
    <path class="icc-lines" d="M468.29,161.11c-2.07.43-4.1-.2-6.11-.44-3.42-.41-6.84-.7-10.26-1.07-3.22-.35-6.37-.14-9.55.07"/>
    <path class="icc-lines" d="M230.69,183.59c2.01,1.44,4.36,2.2,6.68,2.81,2.99.79,5.71,2.19,8.6,3.18,1.03.35,2.38,0,3.08,1.21"/>
    <path class="icc-lines" d="M389.99,145.63c-.12-.12-.33-.23-.35-.36-.45-3.05-2.52-4.38-5.21-5.28-.91-.3-1.51-.28-2.18.24-4.02,3.18-7.22,7.04-9.76,11.49-.57.99-.53,2.14-1.14,3.08"/>
    <path class="icc-lines" d="M386.03,109.3c1.32.29,1.79,1.15,1.24,2.33-.72,1.54-.94,3.27-1.93,4.7-.22.31-.25.84-.19,1.24.57,3.35.96,6.74,2.33,9.89.61,1.4.65,3.01,1.91,4.21.52.5.91.63,1.5.66"/>
  </svg>
            </div>
  
            <div class="anim-splash">
              <svg id="ppp-svg" width="558.2" height="312.4" viewBox="0 0 558.2 312.4">
              <path class="ppp-lines" d="M1,226.96c1.1-.8,2.52-.63,3.48-.28,3.52,1.28,7.15,2.07,10.83,2.37,2.81.23,5.19,1.51,7.72,2.43,2.45.89,4.72,2.41,7.23,2.94,4.14.89,7.96,2.67,12.01,3.77,2.48.68,4.94,1.61,7.4,2.44,3.63,1.22,7.49,1.46,11.15,2.52,3.8,1.1,7.75,1.59,11.53,2.83,1.53.5,3.24.46,4.84.78,3.85.74,7.68,1.56,11.52,2.33,6.67,1.33,12.98,3.98,19.62,5.36,4.95,1.03,9.81,2.6,14.92,2.75,5.15.15,10.1,1.35,14.93,3.07,1.99.71,4.09.92,6.13,1.16,2.03.23,3.93.88,5.92,1.23,5.36.94,10.58,2.55,15.88,3.75,4.27.97,8.61,1.8,12.91,2.73,2.58.56,5.11,1.49,7.56,2.5,5.74,2.36,11.63,4.26,17.65,5.69,6.11,1.45,11.96,3.72,17.95,5.55,3.05.93,6.1,1.75,9.2,2.45,3.47.78,6.95,1.49,10.44,2.15,2.54.48,5.03,1.25,7.57,1.75,1.52.3,2.9.91,4.31,1.48,2.89,1.17,5.98,1.26,9,1.37,3.11.12,6.1.56,8.99,1.68,2.15.84,4.46.69,6.67,1.21,4.16.98,8.42,1.6,12.57,2.6,2.68.65,5.41.81,8.1,1.23,4.59.72,9.21,1.16,13.7,2.61,5.39,1.74,10.92,3.09,16.53,4.02,3.22.54,6.39,1.36,9.72,1.31,1.81-.03,3.65.2,5.4.84,2.14.78,4.44.76,6.66,1.03,3.16.39,6.29,1.22,9.53.98,1.55-.11,3.12-.02,4.68-.02.46.6-.12,1.2,0,1.8"/>
    <path class="ppp-lines" d="M539.05,23.2c-4.43,2.47-9.43,3.15-14.22,4.48-2.33.65-4.43,1.89-6.83,2.39-4.26.88-8.36,2.4-12.59,3.45-6.22,1.55-12.32,3.55-18.39,5.64-4.71,1.62-9.42,3.29-14.2,4.72-5.24,1.57-10.61,2.71-15.8,4.42-3.45,1.13-6.98,1.86-10.47,2.75-3.72.95-7.47,1.81-11.19,2.76-2.64.67-5.5,1.35-8.41,1.15-1.8-.12-3.14,1.49-2.8,3.16.15.71-.2,1.13-.32,1.62-.31,1.3-.9,2.53-1.38,3.79"/>
    <path class="ppp-lines" d="M359.31,43.36c-.69-.73-1.58-1.13-2.52-1.45-1.62-.54-3.32-.84-4.86-1.61-1.84-.92-3.84-.25-5.76-.5-4.61-.59-8.85.98-13.14,2.43-4.49,1.51-8.97,3.13-13.66,3.99-1.81.33-3.35,1.37-5.21,1.68-6.61,1.12-12.84,3.57-19.06,5.95-3.19,1.22-6.14,3.07-9.2,4.62-3.73,1.89-7.09,4.26-10.31,6.96-3.8,3.18-6.61,6.81-8.12,11.56-.44,1.37-.41,2.55-.09,3.8,1.1,4.39,3.14,8.35,5.95,11.86,1.81,2.27,3.33,4.75,5.31,6.92,1.37,1.51,3.33,2.15,4.56,3.72"/>
    <path class="ppp-lines" d="M1.45,226.24c.82-2.89,2.27-5.47,3.97-7.91,3.89-5.58,8.27-10.8,10.9-17.24,1.02-2.49,3.03-4.67,4.7-6.92,2.15-2.9,4.63-5.54,6.38-8.77,2.66-4.88,6.16-9.19,9.84-13.35,2.25-2.55,3.85-5.54,5.63-8.42,1.73-2.8,3.83-5.37,5.86-7.97,1.88-2.41,3.48-5.04,5.26-7.53.92-1.29.94-3.02,2.36-4.17,5.33,2.1,10.34,5.12,15.09,8.63,3.14,2.32,6.61,4.11,10.23,5.62,3.69,1.54,7.43,3.03,10.95,4.91,2.31,1.24,4.47,2.79,6.88,3.88,3.59,1.63,7.22,3.1,11.14,3.84,2.13.4,4.33.83,6.15,2.26.59.47,1.39.77,2.14.9,2.72.48,5.09,1.84,7.53,2.96,5.26,2.42,10.56,4.74,16.04,6.59,5.37,1.81,10.73,3.73,16.21,5.15,3.75.98,7.5,2.04,11.33,2.71.93.16,1.36,1.15,2.33.97"/>
    <path class="ppp-lines" d="M336.64,114.82c0,.77-.2,1.35-.74,2.04-.72.92-.4,2.13.74,2.81,1.05.63,1.44,1.52,1.26,2.71-.18,1.17.73,1.81,1.46,2.31,4.37,2.97,9.18,4.96,14.38,5.95.95.18,1.85.12,1.81,1.47-.02.88.95.69,1.24.66,2.35-.21,4.57.5,6.84.77,3.35.4,6.75.54,10.07,1.14,4,.72,8.04.82,12.06,1.25,5.12.55,10.2,1.42,15.3,2.11,2.45.33,4.92.38,7.38.66,3.15.36,6.26.85,9.35,1.49,1.06.22,2.16.15,3.25.28,3.16.39,6.23,1.18,9.33,1.8,6.25,1.23,11.22-.18,17.84-1.47,3.42-.67,6.77-1.43,10.08-2.53,4.03-1.35,8.13-2.49,12.23-3.6,1.27-.35,2.29-1.52,3.89-1.07-.07,3.18,2.53,4.52,4.57,6.25,1.63,1.38,3.35,2.64,5.03,3.97,1.26,1,2,2.48,2.62,3.82,1.8,3.9,4.39,7.3,6.64,10.89,2.88,4.6,4.52,9.64,6.41,14.63,1.06,2.79,2.07,5.59,3.29,8.34,1.24,2.78,2.64,5.57,3.37,8.58.42,1.73-.02,3.49.48,5.24.67,2.35,1.13,4.77,1.46,7.19.46,3.38,2.05,6.42,2.72,9.72.91,4.52,2.15,9.03,1.94,13.68-.15,3.36-.13,6.75-.82,10.09-.2.98-.03,2.03-.03,3.05"/>
    <path class="ppp-lines" d="M405.28,158.38c1.91,1.45,3.2,3.35,4.05,5.58,1.16,3.02,2.92,5.74,4.31,8.64,1.18,2.46,1.76,5.11,2.31,7.74.29,1.42.79,2.71,1.48,3.95,1.65,2.95,3.42,5.85,5.01,8.83,1.96,3.69,3.52,7.57,4.84,11.52.85,2.55,1.88,4.99,3.11,7.36.8,1.54,1.45,3.11,1.62,4.86.14,1.42,1,2.64,1.52,3.92,1.31,3.23,1.75,6.48,1.78,9.94.02,2.28.82,4.55,1.15,6.85.09.62.53.87.8,1.23,1.67,2.22,1.99,4.7,2,7.41.01,2.97-1.23,5.62-1.69,8.46-.18,1.12-1.1,1.46-1.95.71-1.11-.98-2-2.08-2.58-3.57-1.81-4.64-2.62-9.56-4.26-14.24-.15-.44-.4-.77-.73-1.08"/>
    <path class="ppp-lines" d="M283.92,249.82c4.53,1.51,9.3,2.02,13.85,3.43,2.12.66,4.16,1.82,6.31,2.07,4.31.49,8.31,2.18,12.57,2.86,3.98.64,7.99,1.5,11.84,2.59,4.6,1.31,9.4,1.56,13.93,3.18,2.17.78,4.65.68,6.99.98"/>
    <path class="ppp-lines" d="M436.49,233.26c-1.87.39-3.67.78-5.44,1.72-2.51,1.34-5.35,2.03-8.24,2.02-1.88,0-3.61.68-5.4.86-3.52.36-6.7,1.99-10.24,2.29-3.54.3-7.09.56-10.61.92-3.61.37-7.23.54-10.86,1.5-3.48.92-6.83,2.13-10.17,3.35-2.67.97-5.2,2.42-7.96,3.12-4.76,1.21-9.52,2.64-14.57,1.52-1.57-.35-3.23-.39-4.85-.37-4.92.06-9.61-1.36-14.41-2.06-2.69-.39-5.39-.76-8.08-1.16-1.78-.26-3.4-.73-5.05-1.45-3.04-1.33-6.07-2.58-9.51-2.83-2.37-.17-4.65-1.41-7.03-1.85-4.38-.81-8.43-2.52-12.61-3.93-3.7-1.25-7.47-2.29-11.14-3.63-3.44-1.25-6.72-2.76-9.71-4.88-1-.71-2.14-.96-3.4-1.17-2.69-.45-5.28-1.41-7.75-2.62"/>
    <path class="ppp-lines" d="M361.29,58.84c.12-1.64,1.66-2.23,2.58-3.18,2.27-2.34,4.94-4.29,7.3-6.56,3.58-3.44,7.47-6.72,10.15-10.86,2.07-3.21,4.84-5.65,7.19-8.52,3.71-4.53,7.89-8.68,11.59-13.23,2.2-2.71,4.53-5.42,5.82-8.77.33-.86.35-1.54-.53-2.18-1.96-1.44-3.85-2.99-5.82-4.54-3.55,1.64-6.26,4.26-8.84,7.19-1.44,1.63-3.19,3.02-4.62,4.73-2.73,3.25-5.49,6.5-8.47,9.53-2.65,2.7-5.25,5.45-7.66,8.35-2.1,2.53-4.09,5.17-6.04,7.84-2.5,3.43-5.52,6.48-8.19,9.79-1.48,1.83-3.28,3.4-4.35,5.56-.13.26-.3.58-.54.71-2.59,1.41-3.2,4.12-4.31,6.49-.09.19-.24.36-.36.54"/>
    <path class="ppp-lines" d="M233.18,230.56c2.87,1.48,5.84,2.66,8.98,3.49.89.23,1.73.8,2.71,1.04,2.49.6,4.61-.07,6.48-1.66,2.19-1.86,4.16-4,4.82-6.84,1.08-4.64-.18-7.93-4.48-10.19-.64-.34-1.17-.79-1.76-1.16-1.95-1.22-3.87-2.2-6.3-2.43-1.85-.18-3.62-1.31-5.41-2.06-4.2-1.74-8.22-3.75-11.55-6.99-1.13-1.1-2.61-1.83-3.93-2.73"/>
    <path class="ppp-lines" d="M436.49,63.88c-.8.61-1.69.13-2.52.14-3.36.02-6.69.44-10.08.1-5.09-.51-9.8-2.21-14.57-3.84-5.25-1.79-10.28-4.17-15.49-6.08-4.26-1.57-8.55-3.16-13.12-3.64-.42-.04-.86-.05-1.26-.19-2.34-.81-4.69-1.55-7.2-1.61"/>
    <path class="ppp-lines" d="M330.34,289.06c1.41-2.8.99-3.62-2.52-4.86-2.63-.93-5.42-1.16-8.07-1.88-3-.82-6.04-1.52-9.03-2.4-3.37-.99-6.83-1.71-10.24-2.56-4.69-1.17-9.44-1.97-14.06-3.52-4.07-1.36-8.27-2.44-12.72-2.93-.23,1.5-1.76,2.55-1.65,4.29,1.88.89,3.96,1.23,5.94,1.35,2.41.15,4.53,1.15,6.82,1.57,2.91.54,5.76,1.42,8.65,2.11,3.41.82,6.74,2,10.26,2.33,2.93.28,5.67,1.55,8.63,1.64,1.17.04,2.04.95,3.23,1.12,2.07.3,4.09.91,6.12,1.39"/>
    <path class="ppp-lines" d="M222.21,151.9c.92-1.72,2.86-2.27,4.16-3.58,1.19-1.2,2.58-2.34,3.88-3.27,1.26-.9,2.42-1.94,3.66-2.87,6-4.51,11.66-9.45,17.5-14.17,4.77-3.86,9.32-7.99,14.17-11.76.58-.45,1.19-.85,1.98-1.41-1.25-.91-1.99-1.14-3.07-.99-2.25.31-4.53-.24-6.83,0-3.1.34-6.2.76-9.35.62-1.86-.08-3.7-.3-5.58-.29-2.94,0-5.88.24-8.82.29-3.24.05-6.48.3-9.72.52-2.82.19-5.64.51-8.46.7-3.48.23-6.96.38-10.43.55-2.05.1-3.82,1.6-5.75,1.54-3.41-.12-5.3,2.37-7.26,4.18-2.08,1.92-3.29,4.89-3.86,7.8-.69,3.54-2.21,6.9-2.67,10.44-.9,6.91-4.5,12.76-6.93,19.06-1.1,2.86-1.67,5.79-1.78,8.83-.2,5.46-.53,10.91-.42,16.38.03,1.47.51,3.03-.25,4.52-.14.27.37.87.58,1.32"/>
    <path class="ppp-lines" d="M291.84,117.88c2.8,2.64,5.21,5.55,6.67,9.18.49,1.23,1.24,2.33,1.89,3.47,2.76,4.84,6.78,8.19,11.59,10.76,4.23,2.27,8.15,5.06,12.27,7.5,4.17,2.47,8.43,5.01,12.97,6.57,4.37,1.5,8.07,4.18,12.33,5.83,1.86.72,3.78,1.33,5.63,1.58,3.06.42,5.79,1.83,8.79,2.32,1.56.26,3.1.66,4.68.67,3.42.02,6.84-.11,10.25.03,5.44.23,10.62-1.05,15.85-2.3,3.79-.91,7.1-2.69,10.42-4.56"/>
    <path class="ppp-lines" d="M366.32,93.4c2.4,3.93,4.33,8.17,7.38,11.7,2.63,3.04,5.45,5.93,8.86,8.03,1.84,1.13,4.13,1.58,6.26,2.22,5.13,1.56,10.45,2.35,15.65,3.59.92.22,1.91.14,2.87.2"/>
    <path class="ppp-lines" d="M557.22,221.56c-4.67,1.51-9.18,3.44-13.73,5.26-4.22,1.69-8.5,3.32-12.69,5.08-4.09,1.72-8.42,2.83-12.28,5.12-1.75,1.04-3.78,1.56-5.75,1.97-5.09,1.05-9.85,3.09-14.71,4.81-1.49.53-3.04,1.3-4.38,2.4-2.11,1.73-4.84,2.3-7.37,3.01-3.74,1.04-7.54,2.13-11.49,2.05-3.42-.06-6.86-.04-10.25-.42-2.15-.24-4,.46-5.95.96-.54.14-1.12.44-1.61.33-2.95-.66-5.63.63-8.25,1.41-3.6,1.06-7.16,1.36-10.83,1.32"/>
    <path class="ppp-lines" d="M365.96,227.68c-3.71.99-7.29,2.64-11.16,2.83-4.56.23-9.08,1.31-13.67.76-2.64-.32-5.29-.62-7.91-1.09-5.26-.94-10.28-2.77-15.27-4.55-5.4-1.92-10.23-4.93-14.43-8.93-5.85-5.57-11.78-11.07-18.18-16-3.11-2.39-6.17-4.97-9.73-6.61-3.71-1.71-7.48-3.45-11.66-3.88-1.74-.18-3.33-.87-4.88-1.75-2.81-1.6-5.95-1.26-8.98-1.05-2.28.16-4.55.32-6.84.32-2.85,0-5.5,1.14-8.27,1.63-2.93.52-5.87.67-8.82.65-.25,0-.6-.12-.9-.13-3.31-.15-3.26-.14-3.26,3.05,0,1.86.2,3.73-.14,5.58-.08.43-.07,1.06.19,1.32,1.04,1.06.3,1.73-.38,2.57-2.3-1.24-4.57-2.58-7.02-.47"/>
    <path class="ppp-lines" d="M318.29,83.5c-1.61,2.65-3.62,5-5.58,7.38-2.76,3.34-5.49,6.72-8.64,9.72-5.16,4.91-9.98,10.17-15.24,14.99-3.39,3.1-6.84,6.12-10.29,9.14-1.59,1.39-3.52,2.1-5.42,2.85-1.88.74-3.44,1.88-4.65,3.46-.87,1.13-.34,1.92,1.04,2.54,1.71.77,3.11-.01,4.5-.61,2.81-1.21,5.16-3.14,7.19-5.38,2.83-3.12,5.88-6.02,9.04-8.77,2.99-2.59,6.07-5.15,8.83-7.91,2.7-2.7,5.39-5.53,7.92-8.46,2.24-2.59,4.36-5.31,7.09-7.5,1.45-1.17,2.43-2.99,3.44-4.63.66-1.08,1.18-2.24,2-3.23.37-.44.72-1.05.52-1.6-.54-1.51.5-2.29,1.28-3.26,1.42-1.77,2.7-3.65,3.72-5.71-1.85-1.44-3.38-.01-4.97.33-3.72.82-6.7,3.1-9.49,5.44-4.2,3.52-8.44,6.99-13.01,10.01-4.33,2.86-8.18,6.34-12.21,9.57-2.72,2.18-5.23,4.62-7.96,6.79-1.53,1.22-3.07,2.55-5.19,2.77-.34.03-.84.33-.9.87"/>
    <path class="ppp-lines" d="M354.27,60.46c-2.46,1.45-5,2.45-7.92,1.45-.21-.07-.48-.01-.72,0-1.88.02-3.72-.16-5.59-.48-2.58-.44-5.15.24-7.54,1.23-4.07,1.68-7.6,4.25-11.21,6.76-3.41,2.37-6.99,4.51-10.38,6.92-2.36,1.68-4.57,3.58-6.86,5.37-.24.19-.59.25-.89.37"/>
    <path class="ppp-lines" d="M270.07,228.4c-1.62,2.45-2.26,5.34-3.53,7.95-1.14,2.34-2.08,4.83-3.55,6.94-1.7,2.44-2.74,5.12-3.91,7.78-.59,1.35-1.37,2.62-1.96,3.97-1.3,2.98-2.54,5.99-3.79,9-.32.78-.58,1.59-1.26,2.16"/>
    <path class="ppp-lines" d="M308.75,275.74c.63-1.13.78-2.48,1.77-3.44.4-.39.3-1.06-.03-1.7-4.09-.19-7.95-1.64-11.82-2.97-1.58-.54-3.19-1.06-4.66-1.83-1.02-.53-2.08-.35-3.1-.6-4.36-1.04-8.44-3.24-13.12-3.01-1.41.07-1.84.71-1.97,1.85-.13,1.17.37,1.74,1.61,1.82,2.33.15,4.42,1.25,6.64,1.84,4.61,1.23,9.12,2.86,13.69,4.24,3.41,1.03,6.75,2.41,10.43,2.19.29-.02.6.22.9.34"/>
    <path class="ppp-lines" d="M435.59,64.42c1.71,3.59,4.26,6.58,6.85,9.53,4.32,4.93,7.83,10.42,10.34,16.42,1.56,3.71,2.48,7.71,2.65,11.84.08,2.05.61,4.1,1.05,6.12.6,2.79.91,5.63,1.02,8.47.17,4.19.13,8.4.01,12.6-.08,2.61-1.19,5.04-1.81,7.55-.17.7-.39,1.3-.14,1.99"/>
    <path class="ppp-lines" d="M546.24,113.38c-1.51-.03-3.01.11-4.5.34-.7.11-1.48.22-2.15-.17-2.07-1.21-3.96-.15-5.95.33-2.12.51-3.34,2.21-5.02,3.3-.85.55-1.66,1.22-2.33,1.98-1.1,1.24-2.64,1.27-3.77.8-2.73-1.14-4.65.45-6.65,1.59-.96.55-2,1.26-2.6,2.43-.57,1.1-1.92,1.11-2.99,1.52-.86.33-1.2-.4-1.22-.6-.17-1.52-1.43-1.54-2.4-1.76-1.41-.32-2.89-.64-4.3-.51-1.35.13-2.89.61-3.56,1.93-1.2,2.38-2.98,3.61-5.61,3.99-2,.29-3.87,1.08-5.61,2.25-1.96,1.32-4.39,1.15-6.64,1.44-2.2.29-4.39.5-6.47,1.3"/>
  </svg>
            </div>
  
            <div class="anim-splash">
              <svg id="chipotle-svg" viewBox="0 0 410 300" width="410" height="300">
              <path class="chipotle-lines" d="M4.37,155.86c-.72.65-1.18,1.48-1.62,2.34-.61,1.18-.7,2.21.15,3.45,1.58,2.29,1.26,5.13,1.8,7.72.7,3.32,1.92,6.38,3.83,9.16,1.06,1.54,3,1.53,4.49,2.34.33.18.74.2,1.07.38.76.42,1.75.37,2.33,1.08.6.73-.03,1.55-.02,2.33.02,1.19.46,2.25.97,3.21.66,1.22,2.37,1.58,3.73.91,2.96-1.46,5.84-3.09,8.83-4.46,4.61-2.11,9.43-3.38,14.56-3.27,4.26.09,8.52-.15,12.77.2,1.21.1,2.43.59,3.59.47,1.77-.19,3.58.07,5.27-1.14,2.01-1.44,4.39-.38,7.1-.43-2.86,3.04-3.69,6.65-5.07,9.94-1.51,3.59-3.28,7.12-4.21,10.94-.8,3.27-.85,6.42.96,9.59.9,1.57,1.52,2.45,3.34,1.94.26-.07.59.04.9.13,2.38.67,4.96.83,6.82,2.74.6.61,1.35.34,2,.48,2.61.55,4.59,2.24,6.81,3.49.36.2.72.35,1.09.51"/>
    <path class="chipotle-lines" d="M52.95,117.72c3.97,1.94,7.84,4.04,11.16,7.01,6.51,5.83,12.03,12.52,17.09,19.61,1.28,1.79,2.88,3.23,4.66,4.52,1.96,1.42,3.61,3.23,5.57,4.69,1.4,1.05,2.36,2.54,2.93,4.27.77,2.36,2.73,3.85,4.5,5.38,4.02,3.46,7.5,7.39,10.77,11.54"/>
    <path class="chipotle-lines" d="M234.84,245.8c-1.84-1.71-1.51-3.89-1.03-5.93.61-2.63.77-2.64-1.67-3.41-1.34-.42-2.4-1.18-3.03-2.37-1.42-2.64-3.47-4.86-5.02-7.39-1.14-1.87-2.84-2.32-4.72-2.46-1.39-.1-2.62-.68-3.94-.98-1.53-.35-2.1-1.77-2.34-3.19-.64-3.79-3.01-6.55-5.86-8.7-1.33-1-3.23-1.55-4.75-2.48-1.77-1.08-3.6-2.06-5.27-3.35-1.92-1.48-4.43-1.5-6.61-1.04-3.88.82-7.62.66-11.35-.47-2.54-.77-5.03-.55-7.57-.17-1.49.22-2.96.56-4.5.58-1.4.02-2.59-.19-3.61-1.27-1.7-1.8-3.41-3.58-4.3-5.95"/>
    <path class="chipotle-lines" d="M97.92,111.42c.83-4.42-2.47-7.54-6.84-6.69-4.65.9-8.87,2.74-12.96,5.05-2.59,1.46-5.34,2.59-8.26,3.26-4.06.94-7.82,0-11.32-2.01-2.15-1.24-4.38-1.9-6.85-1.77-1.76.09-3.09,1.21-4.27,2.22-2.15,1.84-4.39,1.98-6.89,1.2-4.89-1.53-9.49-.37-13.84,1.83-5.61,2.84-9.08,7.61-11.56,13.27-1.29,2.95-3.22,5.54-5.38,7.91-1.91,2.1-3.92,4.12-5.94,6.11-.49.48-1.04.87-1.43,1.45-1.82,2.77-1.87,2.79.03,5.93.88,1.45,1.96,2.77,2.95,4.15"/>
    <path class="chipotle-lines" d="M250.68,139.13c-.57.14-.96.56-1.06,1.08-.57,3.03-2.67,5.21-4.17,7.73-.68,1.14-1.15,2.44-2.31,3.27-.25.18-.44.75-.35,1.04.91,3-.29,5.89-.46,8.82-.4,7.09-3.73,12.86-8.7,17.86-1.72,1.73-3.17,3.77-4.9,5.54-.99,1.01-2.13,1.72-3.41,2.37-3.68,1.86-7.59,3.16-11.35,4.82-4.2,1.86-7.32,4.86-9.36,8.99-.39.79-.84,1.56-1.26,2.34"/>
    <path class="chipotle-lines" d="M146.14,39.11c-1.31-2.21-3.41-2.61-5.76-2.72-3.55-.18-7.08-.55-10.62.18-1.23.26-2.46.79-3.79.39.07-1.15.93-1.44,1.8-1.82,2.2-.97,3.92-2.45,4.73-4.97-.65-.76-1.58-1.15-2.57-1.27-.5-.06-.98-.23-1.04-.58-.35-1.86-1.47-.99-2.37-.7-1.14.37-2.11.52-2.92-.71-.4-.61-1.14-1.01-1.77-1.46-1.18-.84-2.43-1-3.58,0-1.13,1-2.26.65-3.03-.22-1.55-1.77-3.22-1.92-5.26-1.07-1.28.53-2.49.75-3.47-.84-.6-.97-1.93-1-2.99-.56-2.68,1.12-5.08.4-7.58-.7-2.55-1.12-5.21-.25-7.7.4-1.27.33-2.76.8-3.62,2.07-2.08,3.08-5.4,3.51-8.64,3.92-4.24.55-7.69,3.93-8.11,8.13-.2,1.99-.53,3.97-.91,5.93-.18.91-1.17,1.3-1.82,1.73-.71.47-1.03,1.05-1.37,1.66,2.18,3.17,3.19,6.7,3.59,10.46.11,1.03-.37,1.5-1.27,1.6-.79.09-1.58,0-2.34-.34-1.66-.72-3.47-.95-5.22-1.02-1.84-.08-3.2,1.26-4.49,2.46-.67.61-1.18,1.42-2.16,1.62"/>
    <path class="chipotle-lines" d="M257.33,139.85c.78-.66,1.56-1.32,2.34-1.98,2.76-2.34,5.77-4.23,9.36-5.03,1.37-.3,2.72-.4,4.14-.04,2.31.59,4.66,1.04,7.01,1.45,1.73.3,3.01,1.45,4.27,2.42,2.25,1.75,4.32,3.72,5.68,6.38,1.27,2.47,3.09,4.63,5.37,6.29.4.29.81.5,1.05.94,1.55,2.85,3.64,5.43,4.49,8.64.7,2.65,2.23,4.85,3.81,7,3.37,4.59,6.12,9.37,7.3,15.1.62,3.01,1.55,5.93,1.29,9.02-.26,3.11-.55,6.09-2.53,8.92-1.41,2.02-2.93,3.92-4.45,5.85-.34.43-.71.31-1.09.35"/>
    <path class="chipotle-lines" d="M143.62,200.29c-.86,1.29-1.58,2.2-3.63,1.77-1.58-.34-3.45.69-5.19,1.11-.59.14-1.22.41-1.79.33-1.52-.21-2.48.47-3.24,1.65-.32.49-.65.96-.98,1.44-.82-.18-1.66-.28-2.44-.56-2.44-.89-4.37.14-6.31,1.44-6.8,4.57-8.81,11.96-11.07,19.1-1.07,3.39-1.24,7.06-1.91,10.58-.12.65-.1,1.06.39,1.45"/>
    <path class="chipotle-lines" d="M62.12,161.43c.09-.64-.36-1-.71-1.45-1.61-2.02-2.67-4.27-3.29-6.82-.97-3.96-2.29-7.87-4.49-11.32-2.54-3.99-6.49-6.32-10.76-8.12-5.49-2.31-11.24-3.69-17.1-4.64-.52-.08-.91-.45-1.43-.4"/>
    <path class="chipotle-lines" d="M119.34,199.03c-1.86.05-3.38-.8-4.86-1.8-4.16-2.8-7.01-7.11-11.19-9.84-2.76-1.8-4.45-4.67-7.47-6.26-4.2-2.21-8.55-3.27-13.18-3.48-1.43-.07-2.85-.42-4.34.1-1.09.38-1.97-.81-3.04-1.03"/>
    <path class="chipotle-lines" d="M367.08,298.87c.1-.41,0-.82.15-1.27.98-2.88.84-3.08-1.76-4.86-2.55-1.75-5.09-3.53-7.55-5.41-2.03-1.55-2.03-2.86-.19-4.65.59-.58,1.24-1.1,1.84-1.63-.98-1.78-.6-3.49,1.23-4.07,2.49-.8,4.74-2.37,7.54-2.26,1.34.05,2.71,0,3.94.45,1.16.43,2.22.65,3.44.33,3.12-.83,6.35-.75,9.53-1.11,1.98-.22,3.95-.46,5.94-.33,1.47.09,2.37-1.01,2.39-1.99.01-.92.18-1.34.84-1.8,1.25-.87,1.42-2.16.87-3.4-.98-2.24-.56-4.07,1.09-5.79.65-.67,1.17-1.5,1.62-2.33.61-1.11.36-2.08-.28-3.26-1.3-2.39-2.33-4.83-4.3-6.88-1.46-1.52-3.23-2.09-4.96-2.53-2.55-.65-4.85-2.03-7.53-2.32-.74-.08-1.51-.7-1.51-1.91,0-1.23-.72-2.17-1.88-2.94-1.17-.78-2.26-1.68-3.01-3.13-1.13-2.16-3.57-2.59-5.85-2.7-1.11-.06-2.11-.08-3.07-.74-1.17-.8-2.58-.93-3.95-1.09-2.12-.25-2.67-1.15-1.79-3.06.18-.39.53-.7.7-1.09.62-1.4.12-2.47-1.24-3.25-2.19-1.26-4.52-1.59-7.02-1.66-2.64-.08-5.23-.63-7.77-1.66-2.7-1.1-5.5-1.97-8.62-1.44-1.51.26-3.32-.38-4.81-1.03-1.76-.77-3.39-.99-5.25-.52-1.38.34-2.87.25-4.31.42-.76.09-1.02-.33-1.27-.91-1.12-2.59-1.13-2.58-3.89-3.83-1.21,1.17-2.59,2.22-4.19,2.94-1.59.72-3.09.43-4.14-.91-.16-.21-.18-.6-.37-.7-2.76-1.36-1.6-3.56-1.25-5.6"/>
    <path class="chipotle-lines" d="M242.58,152.26c-.65-.1-1.23.29-1.4.73-1.38,3.56-4.4,5.64-7.03,8.1-2.65,2.49-5.54,4.69-8.69,6.39-4.49,2.43-8.99,4.89-13.21,7.72-2.72,1.82-5.93,2.9-8.19,5.46-1.36,1.54-2.43,3.16-2.68,5.24"/>
    <path class="chipotle-lines" d="M8.51,150.28c-.13-1.78,1.45-1.62,2.35-1.74,3.17-.42,6.25-1.32,9.53-1.22,2.78.08,5.52.23,8.28.59,4.69.61,8.38,3.42,12.38,5.49,2.19,1.13,4.32,2.26,6.68,3.03,3.46,1.13,6.93,2.27,10.27,3.74,1.27.56,2.55,1.03,3.92,1.39,2.26.59,3.84,2.49,5.25,4.38"/>
    <path class="chipotle-lines" d="M126.89,233.21c-1.55,1.53-3.51,2.45-5.43,3.35-.71.33-1.35.64-1.96,1.14-6.08,5.05-10.53,11.37-14.4,18.17-2.56,4.51-4.91,9.12-6.97,13.87-.08.18-.51.58-.03.88"/>
    <path class="chipotle-lines" d="M241.68,17.88c-.77.57.02,1.53-.64,2.1,1.06.84,2.05.23,2.98.04,3.93-.77,6.56.81,7.76,3.86.78,1.99,1.71,2.11,3.37,2.57,2.01.56,4.05,1.61,4.57,4.19.28,1.37.01,2.4-1.13,3.04-1.69.94-3.4,1.91-5.22,2.5-3.17,1.02-5.63,2.81-7.4,5.67.98,1.57,2.87,1.87,4.21,2.96,2.4,1.96,2.99,4.28,1.64,6.91-.42.82-1.02,1.15-1.68,1.38-1.09.36-1.26.84-1.14,2.02.24,2.33,0,4.68-1.55,6.65-1.66,2.09-3.8,3.04-6.48,2.9-1.44-.08-2.81.47-4.32.44-2.8-.05-5.46-.92-8.28-.88-4.59.06-8.74,1.26-12.6,3.66-.51.32-1.07.57-1.71.55-.84-1.24.31-2.31.75-2.98.79-1.2,1.67-2.47,2.76-3.49,2.67-2.52,3.22-5.4,1.62-8.64-.14-.29-.24-.6-.36-.9"/>
    <path class="chipotle-lines" d="M38.91,200.11c-.94,1.82-1.73,3.7-1.97,5.76-.14,1.18.28,2.35,1.11,3.01,2.11,1.7,2.4,3.86,2.34,6.34-.09,3.37,2.03,6.21,5.01,7.01,1.86.5,3.68.16,5.39-.56,1.75-.74,3.41-1.7,5.22-2.33,1.35-.47,2.72-.69,4.14-.72.55-.01,1.08.13,1.64-.32,1.41-1.13,2.73-.22,4.13.3,4.45,1.68,7.61,4.99,10.78,8.3"/>
    <path class="chipotle-lines" d="M100.62,30.65c-.96-.02-1.73.02-2.38,1.06-2.52,4.04-4.6,8.21-5.39,12.97-.38,2.27-1.25,4.34-2.58,6.36-1.84,2.8-2.47,6.18-3.17,9.47-.73,3.45-.57,7-1.37,10.45-.26,1.13-.2,2.4.13,3.59"/>
    <path class="chipotle-lines" d="M242.94,239.51c-.69-.52-.52-1.27-.54-1.98-.13-4.12.32-8.18,1.02-12.24.24-1.38-.11-2.88-.34-4.3-.11-.67-.1-1.32-.15-1.99-2.78-.74-4.59-2.94-6.82-4.51-1.14-.81-2.04-2.24-3.28-2.58-2.42-.67-4.88-1.61-7.53-1.02-2.29.51-2.68.35-3.73-1.8-.57-1.16-1.23-2.26-1.84-3.4-1.92-3.62-4.99-5.16-9-5.04-.6.02-1.12-.02-1.64.52-1.15,1.19-2.44,2.24-3.74,3.41-1.37-1.05-2.98-1.66-3.95-3.04-.59-.84-1.46-.72-2.19-.85-4.81-.81-9.61-.88-14.39.31-1.32.33-2.66-.14-3.95-.38-2.78-.52-5.53-.43-8.28.05-2.42.42-4.63-.04-6.86-1.05-1.84-.84-3.82-1.36-5.74-2.01"/>
    <path class="chipotle-lines" d="M256.07,179.06c-1.19-2.83-1.58-5.52-.49-8.68,1.03-3.01,1.12-6.39.86-9.67-.36-4.5-1.51-8.82-3.29-12.93-.86-2-.9-4.11-1.45-6.12-.73-2.63-1.46-5.27-3.11-7.62-1.59-2.26-2.45-5-3.99-7.35-1.59-2.41-3.68-4.25-6.17-5.51-3.03-1.54-4.11-4.5-5.68-7.1-.28-.46-1.01-.83-.35-1.88-3.82,1.46-6.65,3.35-8.9,6.22-3.38,4.3-6.3,8.92-9.46,13.37-2.65,3.72-4.62,7.9-7.22,11.67-1.23,1.78-3.24,2.79-4.34,4.66"/>
    <path class="chipotle-lines" d="M135.71,256.6c-.5-.4-1.19-.32-1.6-.14-2.94,1.3-6.15,1.1-9.18,1.8-1.85.43-3.72.8-5.45,1.87-2.08,1.29-4.28,2.37-6.29,3.8-.88.63-1.75,1.23-2.49,2.02-1.88,1.99-4.38,2.84-6.83,3.78-2.34.9-4.99.61-7.22,1.94-.2.12-.81-.11-1.06-.5"/>
    <path class="chipotle-lines" d="M133.37,265.41c-5.26-.52-10.3.12-15.13,2.48-4.27,2.08-8.24,4.79-12.76,6.31-3.17,1.07-6.42,1.57-9.71.38"/>
    <path class="chipotle-lines" d="M99.18,251.02c.34-1.11.06-2.01-.9-2.7-.85-.61-1.53.1-2.14.4-2.72,1.35-4.83,3.5-7.06,5.51-2.54,2.29-5.2,4.45-8.07,6.33-1.44.95-3,1.29-4.68,1.25-1.5-.04-3-.1-4.5-.18-2.13-.11-3.81-1.23-5.4-2.51-.83-.67-1.68-1.32-2.52-1.98"/>
    <path class="chipotle-lines" d="M299.79,180.5c-.65-1.23-1.93-1.58-3.07-1.94-2.22-.69-4.37-1.67-6.83-1.53-1.12.06-2.32-.3-3.41-.69-4.38-1.56-8.72-1.05-13.15-.21-3.71.7-7.15,2.22-10.78,3.13-1.95.49-3.81.34-5.77.06-1.55-.23-3.23.36-4.46,1.4-2.39,2.02-5.38,2.86-8.17,3.77-2.38.78-4.56,1.67-6.61,3.03"/>
    <path class="chipotle-lines" d="M135.71,63.21c-1.33-1.58-3.41-1.06-5.04-1.79-.27-.12-.67-.03-.88-.2-1.89-1.5-4.46-1.66-6.3-3.24-.34-.29-.76-.56-1.09-.49-2.76.5-5.51-.32-8.29.09-3,.45-5.98.89-8.81,2.04-2.36.96-3.44,2.65-3.42,5.21,0,1.26,0,2.52,0,3.78"/>
    <path class="chipotle-lines" d="M60.68,181.94c.06,1.41-.95,2.41-1.48,3.58-1.24,2.75-.73,5.28.61,7.74.67,1.22,1.59,2.27,2.22,3.57.62,1.27.49,2.42.03,3.43-.61,1.33-1.28,2.65-2.25,3.83-1.63,1.99-2.16,6.38-.25,9.18,1.06,1.55,2.39,2.85,3.46,4.38"/>
    <path class="chipotle-lines" d="M240.96,152.98c-.25.04-.48-.04-.73.17-2.74,2.33-6.02,3.87-9.22,5.29-4.37,1.93-8.61,4.16-13.05,5.95-4.18,1.69-8.05,4.09-10.98,7.68-.32.39-.9.92-.38,1.6"/>
    <path class="chipotle-lines" d="M69.86,166.56c-4.39-.81-8.42.4-12.42,2.05-6.09,2.5-11.36,6.42-17.07,9.57-4.77,2.63-9.76,3.54-15.12,2.51-2.4-.46-4.13-1.99-5.93-3.43"/>
    <path class="chipotle-lines" d="M78.31,219.72c.48,1.61.08,3.15-.37,4.68-1.71,5.79-4.71,10.95-7.89,16.03-.85,1.36-1.68,2.71-2.71,3.95-2.43,2.92-3.72,6.47-5.2,9.9-.63,1.47.02,3.04.39,4.47.58,2.27-.09,4.29-.81,6.3-.63,1.76-1.25,3.37-.37,5.25.33.71.35,1.7.18,2.49-.25,1.18-.17,2.31.06,3.42.69,3.32,2.21,4.43,5.37,4.03,3.8-.48,7.27-.28,10.44,1.93,1.76,1.23,3.72,1.09,5.58.83,3.55-.5,6.94-1.6,9.53-4.28,1.88-1.95,3.48-4.12,4.15-6.82"/>
    <path class="chipotle-lines" d="M91.09,175.11c.02,1.03-.02,1.73,1.41,2.25,5.02,1.81,9.69,4.37,14.08,7.45,3.07,2.14,5.96,4.49,8.2,7.45,1.63,2.15,3.38,4.23,4.83,6.55,1.01,1.63,2.88,2.73,4.78,3.45.92.35,1.91.5,2.87.74"/>
    <path class="chipotle-lines" d="M129.05,213.96c3.45,5.14,4.96,11.01,6.52,16.9,1.21,4.57,1.56,9.2,1.54,13.86-.01,3.98-.92,7.92-1.13,11.88-.21,4.12-1.35,8.27-.4,12.39.41,1.8.75,3.62,1.22,5.41.7,2.64,3.08,3.46,5.22,4.28,4.06,1.55,8.18,1.65,12.22-.3,3.07-1.48,5.07-4.03,7.08-6.7,1.21.66,2.46,1.32,3.7,2.02,1.29.73,2.44.76,3.43-.53.28-.37.69-.63,1.05-.95,1.17.24,1.96,1.07,2.9,1.65,1.57.96,3.37.74,4.85-.53.98-.84,2.31-1.24,3.04-2.35.44-.67.89-.21,1.28-.14.68.14,1.39.35,1.97.71,2.87,1.76,5.6.56,8.27-.41,1.78-.65,2.18-2.68,1.49-4.48-1.02-2.64-2.21-5.29-1.84-8.26"/>
    <path class="chipotle-lines" d="M214.51,34.43c1.61-3.36,2.57-7.12,5.76-9.53.42-.32.76-.76.51-1.25-.26-.51-.86-.6-1.41-.55-1.22.11-2.44.25-3.54.77-.83-.33-1.12-1.1-1.46-1.69-1.19-2.04-2.92-3.06-4.85-2.06-1.48.77-2.22.17-3.09-.63-1.67-1.53-2.05-1.42-3.64.15-1.13,1.12-.86,2.58-1.4,3.82-1.06,2.44-2.52,4.67-3.48,7.17-.77,1.99-.3,3.99-.66,5.96"/>
    <path class="chipotle-lines" d="M233.04,118.98c0,.65-.05,1.13-.68,1.66-1.54,1.29-2.76,2.95-4.71,3.75-.29.12-.56.42-.72.71-1.92,3.57-5.16,6.01-7.65,9.07-2.92,3.59-5.37,7.65-6.4,12.33-.23,1.02-.52,2.03-.7,3.06-.09.53-.22,1.22.7,1.26"/>
    <path class="chipotle-lines" d="M169.53,161.34c1.22,3.04,2.34,6.12,3.23,9.27.5,1.75.34,3.59-.26,5.18-.46,1.21-.49,2.48-1.02,3.62-.15.33-.25.75-.13.89,1.67,2,1.19,4.4,1.22,6.68.02,1.92-.1,3.84-.16,5.76"/>
    <path class="chipotle-lines" d="M231.6,112.32c-.68-2.53-1.58-4.99-2.21-7.54-.7-2.85-3.03-4.47-5.71-4.55-1.95-.06-4.1.69-5.61,2.53-1.77,2.16-3.78,4.13-5.74,6.13-.82.84-2.1.9-3.09,1.57-.61.42-1.21.82-1.76,1.32"/>
    <path class="chipotle-lines" d="M246.36,56.55c-1.89,1.11-3.02-.55-4.29-1.31-1.81-1.08-3.35-2.59-5.25-3.54-3.35-1.67-6.88-2.73-10.6-3.32-1.25-.2-2.52-.6-3.79-.83-2.72-.5-5.32-.19-7.74,1.26"/>
    <path class="chipotle-lines" d="M168.63,61.77c-.38-2.11-3.53-4.4-6.12-4.5-4.21-.18-7.52,1.57-10.66,4.27-3.83,3.3-6.13,7.51-8.21,11.94-.28.59-.26,1.31-.38,1.97"/>
    <path class="chipotle-lines" d="M235.2,189.14c1.29-2,3.26-.92,4.66-.62,4.97,1.08,9.04,3.9,12.41,7.66.92,1.02,1.62,2.31,2.13,3.59.32.8-.16,1.87-.71,2.46-1.36,1.45-1.85,3.54-3.55,4.72-.24.16-.41.37-.37.72.14,1.16-.3,2.18-.66,3.25-.23.69-.25,1.54.64,2.18,1.06.76,1.85,1.86,2.89,2.68,1.06.84,1.09,2.26.19,3.39-.39.5-.82.97-1.26,1.44-.92,1-1.51,2.07-.72,3.42,1.59,2.7.29,5.16-.77,7.54-.92,2.05-2.56,3.65-4.46,4.86-1.35.86-1.94,2.3-2.78,3.5-.49.7-.52,2.01.1,3"/>
    <path class="chipotle-lines" d="M122.93,127.97c.38,2.97,1.37,5.62,3.39,7.94,2.28,2.62,3.34,6.01,5.13,8.93,2.18,3.55,3.1,7.62,5.11,11.21.45.81.72,1.86.22,2.86"/>
    <path class="chipotle-lines" d="M296.73,211.8c.09,1.1-.93,1.56-1.49,2.11-2.96,2.92-5.99,5.78-9.16,8.47-2.24,1.9-5.09,1.88-7.89,1.85-2.64-.03-5.3-.28-7.92.33-.46.11-.96.01-1.44.01"/>
    <path class="chipotle-lines" d="M91.81,69.69c2.01-.46,4.01-.6,5.94.36.94.47,2.05-.11,2.45-.45,1.54-1.31,3.19-.57,4.75-.6,3.31-.06,5.74,1.42,7.61,4.24,1.53,2.31,3.77,3.54,6.79,2.59,2-.63,3.17,1.02,4.54,2.36,2.62-2.43,5.87-3.09,9.12-3.02,3.11.06,6.27-.07,9.36.62.23.05.54.13.72-.17"/>
    <path class="chipotle-lines" d="M166.83,54.94c.22-.57.03-.99-.35-1.45-1.34-1.61-2.02-3.41-2.46-5.54-.95-4.57-3.48-8.3-7.84-10.4-.83-.4-2.03-.19-3.03-.03-2.05.33-4.11.73-6.11,1.27-.79.22-1.45.92-2.17,1.4"/>
    <path class="chipotle-lines" d="M174.39,97.57c.41-3.59.85-7.17.56-10.79-.06-.71-.15-1.42-.57-1.97-1.27-1.66-1.61-3.62-1.98-5.59-.12-.62-.29-1.46-.73-1.76-2.47-1.67-2.48-4.5-3.33-6.9-.21-.6-.42-.49-.78-.52"/>
    <path class="chipotle-lines" d="M177.27,31.19c-1.37-.48-2.66-.1-3.96.35-3.09,1.08-9.67,5.01-11.78,7.11,1,1.03,2.11,1.77,3.49,2.29,2.08.79,3.78,2.23,5.23,3.92.65.76.34,1.47-.18,2.16-1.78,2.37-1.34,6.75.91,8.63.98.82,2.32.73,3.42.82,2.74.23,4.83,1.67,6.59,3.38,2.73,2.65,3.72,2.44,7.05,1.32,1.53-.51,2.78-.18,3.95.47.93.52,2.01.87,2.73,1.75,1.17,1.41,2.68.97,4.13.72,4.34-.75,8.48-.59,11.86,2.71,1.15,1.12,1.46,3.94.73,5.38"/>
    <path class="chipotle-lines" d="M256.79,32.45c-2.29.67-4.58,1.08-7.02.97-1.72-.08-3.49.69-4.82,1.77-3.05,2.49-6.19,4.85-9.36,7.2-1.97,1.47-4.35,2.01-6.33,3.37"/>
    <path class="chipotle-lines" d="M196.34,95.77c-2.18.05-3.94-1.26-5.91-1.87-2.66-.82-4.92-.37-7.03,1.17-2.19,1.6-4.76,2.44-7.25,3.04-1.17.28-3.07.26-4.45-.56-1.77-1.05-3.39-1.29-5.25-.02-2.27,1.55-4.84,1.87-7.53,1.12-2.31-.64-4.58-.65-6.85.32-1.05.45-2.14.84-3.41.81-1.18-.03-2.06,1.01-2.67,1.94-1.76,2.71-3.79,5.03-7.05,5.91-1.17.32-1.41,1.59-2.31,2.2-.1.07-.19.7.15,1.05,2.43,2.5,3.09,5.71,3.39,8.99.22,2.41,2.19,3.43,3.43,5.05,2.25,2.97,5.46,3.65,8.84,4.13,3.84.55,7.48,1.58,10.66,4.06,1.62,1.26,3.87.97,5.89.95,1.01,0,2.02-.06,2.7.91"/>
    <path class="chipotle-lines" d="M201.38,213.78c.73,1.9,1.82,3.55,3.21,5.06,1.27,1.38,1.51,3.34,1.39,5.01-.35,4.88-2.67,8.92-6.42,12.03-1.61,1.34-2.38,2.74-2,4.89.32,1.81.43,3.42,2.32,4.59.99.62.79,2.38.42,3.67-.36,1.23-1.19,1.7-2.51,1.66-1.28-.04-2.42.52-3.24,1.58"/>
    <path class="chipotle-lines" d="M119.7,143.8c1.99.13,2.32.48,1.97,2.34-.96,5.05-1.64,10.14-1.42,15.29.19,4.38.24,8.78.77,13.13.57,4.68,2.82,8.84,5.05,12.92.56,1.02.35,2.42,1.69,3.16.79.44,1.49,1.06,2.37,1.38,3.02,1.08,5.87,2.51,8.65,4.12.63.37.76.94,1.07,1.46"/>
    <path class="chipotle-lines" d="M63.92,164.31c-4.2-.44-8.27-1.46-12.23-2.89-2.22-.81-4.53-1.1-6.84-1.44-5.65-.84-11.28-.03-16.91.07-6,.1-12.01.42-17.99,1.03-1.39.14-2.5-.15-2.7-1.8"/>
    <path class="chipotle-lines" d="M170.07,156.04c.24-.18.48-.51.72-.52,3.34-.05,6.25-2.38,9.53-2.09,2.98.26,5.74,1.58,8.31,3.29,2.54,1.68,2.82,4.43,3.71,6.89.33.92.76,1.11,1.65,1.29,3.61.69,5.92,2.09,6.63,5.9.74,3.97-.17,7.83-1.16,11.7-.84,3.25-2.91,5.82-4.17,8.81-.19.46-.95,1.18.15,1.61"/>
    <path class="chipotle-lines" d="M106.2,106.39c.73,5.72.36,11.26-1.73,16.76-1.6,4.21-3.66,8.27-4.59,12.74-.39,1.86-.76,3.63-.6,5.59.18,2.09-1.14,4.1-.82,6.29.25,1.68.4,3.37.75,5.03.24,1.16,2.18,1.95,3.38,1.43,2.21-.96,4.26-2.16,5.95-3.93,2.11-2.21,4.49-4.2,5.57-7.2"/>
    <path class="chipotle-lines" d="M104.94,91.09c1.55.22,2.51,1.46,3.75,2.21.6.36,1.24.86,1.82.8,2.76-.27,3.32,1.77,3.92,3.66.27.85.5,1.68,1.12,2.32,2.84,2.97,4.64,6.54,6.36,10.23.99,2.14,2.34,4.09,2.64,6.51.06.48.61.33.89.54"/>
    <path class="chipotle-lines" d="M125.27,37.49c-1.29,1.3-3.07,1.56-4.68,2.14-5.49,2.01-10.99,4.01-15.81,7.42-1.3.92-2.08,2.32-2.36,3.93"/>
    <path class="chipotle-lines" d="M70.4,167.01c.77.86,1.91.87,2.88,1.25,5.51,2.11,11.32,3.21,16.9,5.06,1.71.57,3.36,1.3,5.04,1.96"/>
    <path class="chipotle-lines" d="M112.32,130.67c.1,2.05,1.18,4.02.53,6.11-.72,2.31-1.95,4.3-3.94,5.77-1.82,1.34-3.63,2.66-5.07,4.47-1.64,2.05-2.39,4.41-3.03,6.86"/>
  </svg>
            </div>
        </>
    );
};