export default function PageNotFound() {
    return (
        <>
        <h1>
            404
        </h1>
        <p>Page Not Found.</p>
        </>
    )
}
